import { WalletProvider } from "@solana/wallet-adapter-react";
import {
    getLedgerWallet,
    getPhantomWallet,
    getSlopeWallet,
    getSolletExtensionWallet,
    getSolletWallet,
} from "@solana/wallet-adapter-wallets";
import { ConnectionProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import * as React from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { getEnvironmentVars } from "../config";
import { DEVNET_RPC, MAINNET_RPC } from "../utils/consts";
import { CASTLE_ICON } from "../consts";
import { getWalletAdapterNetwork } from "../utils/getWalletAdapterNetwork";
import { Outlet, useMatch } from "react-router-dom";
import { LoadingBoundary } from "./LoadingBoundary";
import { FadeIn } from "./FadeIn";
import { Routes } from "../routes";
import { ClusterStatusFetcher } from "./ClusterStatusFetcher";
import { ClusterStatus } from "./ClusterStatus";

// // // //

export function AppLayout() {
    const env = getEnvironmentVars();
    const network = getWalletAdapterNetwork({ env });

    // Defines flag to hide WalletButton in the navbar
    const showWalletButton: boolean =
        useMatch(Routes.vaultIndividualAsWallet) === null;

    // Juice the loading UX
    const [loading, setLoading] = React.useState<boolean>(true);
    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 800);
    }, []);

    const wallets = React.useMemo(
        () => [
            getPhantomWallet(),
            getSlopeWallet(),
            getLedgerWallet(),
            getSolletWallet({ network }),
            getSolletExtensionWallet({ network }),
        ],
        [network]
    );

    return (
        <ConnectionProvider
            endpoint={
                network == WalletAdapterNetwork.Devnet
                    ? DEVNET_RPC
                    : MAINNET_RPC
            }
        >
            <WalletProvider wallets={wallets} autoConnect={true}>
                <WalletModalProvider logo={CASTLE_ICON}>
                    <Navbar
                        showWalletButton={showWalletButton}
                        deploymentEnv={env.deploymentEnv}
                        onRefresh={() => {
                            // castleApi.refresh();
                            console.log("refresh...");
                        }}
                    />
                    <ClusterStatusFetcher
                        cluster={getWalletAdapterNetwork({ env })}
                    >
                        {({ status, loading, error }) => {
                            if (loading || error) {
                                return null;
                            }

                            return <ClusterStatus meanMs={status.mean_ms} />;
                        }}
                    </ClusterStatusFetcher>
                    <LoadingBoundary loading={loading}>
                        <FadeIn show={!loading} duration={1000}>
                            <Outlet />
                            <Footer />
                        </FadeIn>
                    </LoadingBoundary>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
}
