import classnames from "classnames";
import React from "react";

// // // //

interface Props {
    length?: number;
    speed?: number;
    renderPlaceholder?: boolean;
}

interface State {
    count: number;
}

/**
 * DotDotDots
 * Renders an animated ". . ." ellipsis for loading UI throughout the app
 * @param props.length The total number of doot-doots to render
 * @param props.renderPlaceholder
 *          Whether or not to always render all the doot-doots at once and animate.
 *          Useful to doing something like `Loading...` without the "Loading" text
 *          shifting as doot-doots are added/removed
 */
export class DotDotDots extends React.Component<Props, State> {
    intervalID: undefined | ReturnType<typeof setTimeout> = undefined;

    constructor(props: Props) {
        super(props);
        // Set initial state.count value to 0
        this.state = {
            count: 0,
        };
    }

    componentWillUnmount() {
        // Clear this.intervalID if component unmounts
        if (this.intervalID === undefined) {
            return;
        }
        clearInterval(this.intervalID);
    }

    componentDidMount() {
        const { length = 3, speed = 800 } = this.props;

        this.intervalID = setInterval(() => {
            // Reset count to 0 if it equals length
            if (this.state.count === length) {
                this.setState({
                    count: 0,
                });
                return;
            }
            // Increment count
            this.setState({
                count: this.state.count + 1,
            });
        }, speed);
    }

    render() {
        const { length = 3, renderPlaceholder = false } = this.props;

        // Determine the total number of doot-doots to render
        // Allows renderPlaceholder behavior in JSX to work correctly
        const { count } = this.state;
        let numberOfDots = this.state.count;
        if (renderPlaceholder) {
            numberOfDots = length;
        }

        return (
            <React.Fragment>
                {Array.from(Array(numberOfDots).keys()).map((k, index) => {
                    // Render all doot-doots at once and control visibility with CSS
                    if (renderPlaceholder) {
                        return (
                            <span
                                key={k}
                                className={classnames("mr-1", {
                                    invisible: index + 1 > count,
                                })}
                            >
                                .
                            </span>
                        );
                    }

                    // Return null if the current count is greater than the total
                    if (count > numberOfDots) {
                        return null;
                    }

                    // Return a single, always-visible doot-doot
                    return (
                        <span key={k} className="mr-1">
                            .
                        </span>
                    );
                })}
            </React.Fragment>
        );
    }
}
