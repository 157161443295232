export const CASTLE_ICON =
    "https://user-images.githubusercontent.com/10917080/150696282-74266ef5-cd9f-4b76-b823-21672c203554.svg";

export const USDC_ICON =
    "https://cdn.rwa.xyz/images/cryptocurrency-icons/128/color/usdc.png";
// "https://raw.githubusercontent.com/trustwallet/assets/f3ffd0b9ae2165336279ce2f8db1981a55ce30f8/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png";

export const SOL_ICON =
    "https://user-images.githubusercontent.com/10917080/150696042-62f60b04-8989-44ac-9fde-9ea01ad19364.svg";

export const CASTLE_USDC_LP_NAME = "cstlUSDC";

/**
 * Canonical registry of tokens used in the Swap UI
 * Eventually this will grow to accommodate MANY more tokens
 * Initialized with our own custom SOL token and LP token
 * Used by Swap component for to/from transactions
 */
export interface TokenRegistryData {
    symbol: string;
    icon: string;
}

export const USDC_TOKEN_REGISTRY: TokenRegistryData = {
    symbol: "USDC",
    icon: USDC_ICON,
};

export const CASTLE_TOKEN_REGISTRY: TokenRegistryData = {
    symbol: CASTLE_USDC_LP_NAME,
    icon: CASTLE_ICON,
};

/**
 * Exports URL constants used throughout the app
 */
enum CastleUrls {
    www = "www",
    docs = "docs",
    github = "github",
    twitter = "twitter",
    discord = "discord",
}

export const CASTLE_URLS: { [key in CastleUrls]: string } = {
    www: "https://castle.finance",
    docs: "https://docs.castle.finance",
    github: "https://github.com/castle-finance",
    twitter: "https://twitter.com/castlefinance",
    discord: "https://discord.gg/mkc4wWMEhp",
};

/**
 * Exports market URLs
 */
export interface MarketUrls {
    port: string;
    jet: string;
    solend: string;
}

export const DEVNET_MARKET_URLS: MarketUrls = {
    port: "https://mainnet.port.finance/",
    jet: "https://devnet.jetprotocol.io/",
    solend: "https://devnet.solend.fi/dashboard",
};

export const MAINNET_MARKET_URLS: MarketUrls = {
    port: "https://mainnet.port.finance/",
    jet: "https://app.jetprotocol.io/",
    solend: "https://solend.fi/dashboard",
};
