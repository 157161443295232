import {
    DeploymentEnvs,
    StrategyTypes,
    VaultConfig,
} from "@castlefinance/vault-core";
import React, { useEffect, useState } from "react";
import { getEnvironmentVars } from "../../config";

// // // //

// Pull API_GATEWAY from EnvironmentVars
const API_GATEWAY = getEnvironmentVars().apiGateway;

interface State {
    loading: boolean;
    error: boolean;
    vaults: VaultConfig<DeploymentEnvs>[];
}

/**
 * VaultListFetcher
 * Fetches list of VaultConfigs via api.castle.finance
 */
export function VaultListFetcher(props: {
    children: (childProps: {
        loading: boolean;
        error: boolean;
        allVaults: VaultConfig<DeploymentEnvs>[];
        filteredVaults: VaultConfig<DeploymentEnvs>[];
        maxYieldVaults: VaultConfig<DeploymentEnvs>[];
        equalAllocationVaults: VaultConfig<DeploymentEnvs>[];
    }) => React.ReactNode;
}) {
    // Hook for loading + error + vaults state
    const [state, setState] = useState<State>({
        loading: true,
        error: false,
        vaults: [],
    });

    const { loading, error, vaults } = state;

    // Setup useEffect hook to request data on-mount
    // Implements fetch API invocation to fetch VaultData
    // Setup .then() + .catch() handlers to update component state
    useEffect(() => {
        fetch(`${API_GATEWAY}/vaults`)
            .then(async (res) => await res.json())
            .then((vaultData) => {
                setState({
                    loading: false,
                    error: false,
                    vaults: vaultData,
                });
            })
            .catch(() => {
                setState({
                    loading: false,
                    error: true,
                    vaults: [],
                });
            });
    }, []);

    // Filters vaults that match current DeploymentEnvironment
    const env = getEnvironmentVars();
    const filteredVaults = vaults.filter(
        (v) => v.deploymentEnv === env.deploymentEnv
    );

    // Filter for maxYieldVaults
    const maxYieldVaults = filteredVaults.filter(
        (v) => v.strategy_type === StrategyTypes.maxYield
    );

    // Filter for equalAllocationVaults
    const equalAllocationVaults = filteredVaults.filter(
        (v) => v.strategy_type === StrategyTypes.equalAllocation
    );

    // Expose state via props.children
    return (
        <React.Fragment>
            {props.children({
                loading,
                error,
                allVaults: vaults,
                filteredVaults,
                maxYieldVaults,
                equalAllocationVaults,
            })}
        </React.Fragment>
    );
}
