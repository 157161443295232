import { TransactionSignature } from "@solana/web3.js";
import { Wallet, Provider } from "@project-serum/anchor";
import { VaultClient } from "@castlefinance/vault-sdk";

// // // //

/**
 * Send withdraw transactions
 * @param vaultClient
 * @param provider
 * @param amount
 * @returns ignore rebalance transactions, only return actual withdrawal
 */
export async function withdraw(props: {
    vaultClient: VaultClient;
    provider: Provider;
    amount: number;
    decimals: number;
}): Promise<TransactionSignature> {
    const { vaultClient, provider, amount, decimals } = props;
    const sigs: TransactionSignature[] = await vaultClient.withdraw(
        provider.wallet as Wallet,
        amount * 10 ** decimals
    );
    // Pulls last entry because withdraw sends multiple transactions
    // We only care about the LAST withdrawl
    const withdrawSig: TransactionSignature = sigs[sigs.length - 1];
    return withdrawSig;
}
