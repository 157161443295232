import { XIcon } from "@heroicons/react/solid";
import { Button } from "../Button";

// // // //

export function TransferCard(props: {
    label: string;
    onDismiss?: () => void;
    disableDismiss?: boolean;
    children: React.ReactNode;
}) {
    const { label, onDismiss, disableDismiss = false } = props;

    return (
        <div className="block bg-mid-gray border-2 border-light-stroke rounded-2xl">
            <div className="border-b-2 border-light-stroke p-6 flex items-center justify-between select-none">
                <h4 className="text-2xl font-bold text-gray-100">{label}</h4>
                {onDismiss && (
                    <button
                        disabled={disableDismiss}
                        onClick={() => {
                            onDismiss();
                        }}
                        className="bg-light-stroke text-gray-200 rounded-lg w-6 h-6 flex justify-center items-center transition-colors hover:bg-gray-700"
                    >
                        <XIcon className="h-4 w-4" />
                    </button>
                )}
            </div>

            {props.children}
        </div>
    );
}
