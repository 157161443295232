import memoize from "lodash.memoize";
import {
    AreaChart,
    AreaSeries,
    ChartTooltip,
    ChartZoomPan,
    DiscreteLegend,
    DiscreteLegendEntry,
    Gridline,
    GridlineSeries,
    Line,
    LinearAxisLine,
    LinearAxisTickLabel,
    LinearXAxis,
    LinearXAxisTickSeries,
    LinearYAxis,
    LinearYAxisTickSeries,
    TooltipArea,
} from "reaviz";
import { VaultHistory } from "../../components/VaultHistoryFetcher/types";

// // // //

/**
 * Defines enum of labels for APY chart
 */
export enum ApyLabels {
    castle = "Reserve",
    solend = "Solend",
    jet = "Jet",
    port = "Port",
}

/**
 * Map each APY to its corresponding color
 */
export const mapApyToColor: { [key in ApyLabels]: string } = {
    [ApyLabels.castle]: "#4fff95",
    [ApyLabels.solend]: "#ff5c28",
    [ApyLabels.jet]: "#7BCBCD",
    [ApyLabels.port]: "#826DF9",
};

/**
 * Defines default sort order for APY data
 */
const sortOrder = [
    ApyLabels.castle,
    ApyLabels.port,
    ApyLabels.jet,
    ApyLabels.solend,
];

/**
 * TooltipContent
 * Render content for chart tooltip
 */
function TooltipContent(props: {
    x: string;
    data: Array<{ key: ApyLabels; value: number; x: string }>;
}) {
    // Sorts `Castle` to the top
    const data: Array<{ key: ApyLabels; value: number; x: string }> =
        props.data.sort((a, b) =>
            sortOrder.indexOf(a.key) > sortOrder.indexOf(b.key) ? 1 : -1
        );

    return (
        <div className="flex flex-col w-48">
            <p className="text-lg mb-3">{formatDate(props.x)}</p>
            {data.map((d) => (
                <div key={d.key} className="flex justify-between">
                    <p className="flex items-center">
                        <span
                            className="h-3 w-3 rounded-full mr-2"
                            style={{ backgroundColor: mapApyToColor[d.key] }}
                        />
                        <span
                            className="mr-2"
                            style={{ color: mapApyToColor[d.key] }}
                        >
                            {d.key}
                        </span>
                    </p>
                    <p key={d.key} className="flex items-center">
                        <span
                            className="tabular-nums"
                            style={{ color: mapApyToColor[d.key] }}
                        >
                            {d.value.toFixed(2)}
                            <span className="mr-0.5 text-xs relative bottom-0.5">
                                %
                            </span>
                        </span>
                    </p>
                </div>
            ))}
        </div>
    );
}

/**
 * formatDate
 * Formats date values for the chart
 */
function formatDate(date: unknown) {
    if (!date) {
        return "";
    }

    return (date as Date).toLocaleString("en-us", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour12: false,
    });
}

/**
 * formatXAxis
 * Function to format tick values for the x-axis
 */
function formatXAxis(date: unknown) {
    if (!date) {
        return "";
    }

    return (date as Date).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        timeZone: "utc",
    });
}

/**
 * getEveryNthElement
 * Returns a sub-set of an array with every nth element present
 * i.e. getEveryNthElement(1, 2, 3, 4, 5, 6], 2) will return [1, 3, 5]
 * Used to display allocations at a granularity of 24 hours until
 * the API supports different different timeseries bucket sizes
 */
function getEveryNthElement(arr: Array<any>, nth: number) {
    const result = [];

    for (let i = 0; i < arr.length; i += nth) {
        result.push(arr[i]);
    }

    return result;
}

// Formats the data for the AllocationsChart
function getData(history: VaultHistory) {
    const allocationHistories = getEveryNthElement(
        Object.keys(history.reserveAllocation),
        24
    ).map((date) => {
        const totalAmount =
            parseFloat(history.reserveAllocation[date]) +
            parseFloat(history.solendAllocation[date]) +
            parseFloat(history.jetAllocation[date]) +
            parseFloat(history.portAllocation[date]);

        return {
            date: new Date(date),
            reserve:
                (parseFloat(history.reserveAllocation[date]) / totalAmount) *
                100,
            solend:
                (parseFloat(history.solendAllocation[date]) / totalAmount) *
                100,
            jet: (parseFloat(history.jetAllocation[date]) / totalAmount) * 100,
            port:
                (parseFloat(history.portAllocation[date]) / totalAmount) * 100,
        };
    });

    return [
        {
            key: ApyLabels.castle,
            data: [
                ...allocationHistories.map((h) => {
                    return {
                        key: h.date,
                        data: h.reserve,
                    };
                }),
            ],
        },
        {
            key: ApyLabels.solend,
            data: [
                ...allocationHistories.map((h) => {
                    return {
                        key: h.date,
                        data: h.solend,
                    };
                }),
            ],
        },
        {
            key: ApyLabels.jet,
            data: [
                ...allocationHistories.map((h) => {
                    return {
                        key: h.date,
                        data: h.jet,
                    };
                }),
            ],
        },
        {
            key: ApyLabels.port,
            data: [
                ...allocationHistories.map((h) => {
                    return {
                        key: h.date,
                        data: h.port,
                    };
                }),
            ],
        },
    ];
}

const getDataMemo = memoize(getData);

// // // //

/**
 * AllocationsChart
 * Renders chart for historical allocations
 */
export function AllocationsChart(props: { history: VaultHistory }) {
    const { history } = props;
    const data = getDataMemo(history);

    return (
        <div className="mt-4">
            <DiscreteLegend
                orientation="horizontal"
                className="pb-6 px-12 w-full"
                entries={[
                    <DiscreteLegendEntry
                        key={ApyLabels.castle}
                        label={ApyLabels.castle}
                        color={mapApyToColor[ApyLabels.castle]}
                    />,
                    <DiscreteLegendEntry
                        key={ApyLabels.jet}
                        label={ApyLabels.jet}
                        color={mapApyToColor[ApyLabels.jet]}
                    />,
                    <DiscreteLegendEntry
                        key={ApyLabels.port}
                        label={ApyLabels.port}
                        color={mapApyToColor[ApyLabels.port]}
                    />,
                    <DiscreteLegendEntry
                        key={ApyLabels.solend}
                        label={ApyLabels.solend}
                        color={mapApyToColor[ApyLabels.solend]}
                    />,
                ]}
            />
            <div className="pl-6 pr-4 md:pl-4 md:pr-4">
                <AreaChart
                    height={300}
                    data={data}
                    zoomPan={<ChartZoomPan />}
                    gridlines={
                        <GridlineSeries
                            line={
                                <Gridline
                                    direction="all"
                                    strokeColor={"#222234"}
                                />
                            }
                        />
                    }
                    series={
                        <AreaSeries
                            type="stacked"
                            animated={false}
                            tooltip={
                                <TooltipArea
                                    placement="right"
                                    height={300}
                                    tooltip={
                                        <ChartTooltip
                                            className="rounded-xl border-4 border-dark-100 p-4"
                                            content={(value: any) => {
                                                return (
                                                    <TooltipContent
                                                        x={value.x}
                                                        data={value.data}
                                                    />
                                                );
                                            }}
                                        />
                                    }
                                />
                            }
                            line={<Line animated={false} strokeWidth={2} />}
                            colorScheme={(data: any) => {
                                return mapApyToColor[data[0].key as ApyLabels];
                            }}
                        />
                    }
                    yAxis={
                        <LinearYAxis
                            scaled={true}
                            type="value"
                            axisLine={<LinearAxisLine />}
                            roundDomains
                            domain={[0, 100]}
                            tickSeries={
                                <LinearYAxisTickSeries
                                    label={
                                        <LinearAxisTickLabel
                                            rotation={0}
                                            text="USDC"
                                            format={(a) => {
                                                return `${a}%`;
                                            }}
                                            fontSize={14}
                                            position="start"
                                            padding={{
                                                fromAxis: 10,
                                                alongAxis: 0,
                                            }}
                                        />
                                    }
                                />
                            }
                        />
                    }
                    xAxis={
                        <LinearXAxis
                            type="time"
                            scaled={false}
                            // domain={domain} // Set this from ChartBrush
                            axisLine={<LinearAxisLine strokeWidth={1} />}
                            tickSeries={
                                <LinearXAxisTickSeries
                                    label={
                                        <LinearAxisTickLabel
                                            text="Date"
                                            fontSize={14}
                                            position="end"
                                            padding={{
                                                fromAxis: 10,
                                                alongAxis: 0,
                                            }}
                                            format={(date) => {
                                                return formatXAxis(date);
                                            }}
                                        />
                                    }
                                />
                            }
                        />
                    }
                />
                {/* Render Brush for Allocations chart */}
                {/* <AreaChart
                    height={25}
                    data={data}
                    // margins={[0, 0, 0, -25]}
                    brush={<ChartBrush onBrushChange={onBrushChange} />}
                    gridlines={null}
                    series={
                        <AreaSeries
                            type="stacked"
                            animated={false}
                            line={<Line animated={false} strokeWidth={1} />}
                            colorScheme={(data: any) => {
                                return mapApyToColor[data[0].key as ApyLabels];
                            }}
                        />
                    }
                    yAxis={
                        <LinearYAxis
                            scaled={false}
                            type="value"
                            axisLine={<LinearAxisLine />}
                            roundDomains
                            tickSeries={
                                <LinearYAxisTickSeries
                                    label={null}
                                    tickSize={0}
                                />
                            }
                        />
                    }
                    xAxis={
                        <LinearXAxis
                            type="time"
                            scaled={false}
                            axisLine={<LinearAxisLine strokeWidth={1} />}
                            tickSeries={
                                <LinearXAxisTickSeries
                                    label={
                                        <LinearAxisTickLabel
                                            text="Date"
                                            fontSize={14}
                                            position="end"
                                            padding={{
                                                fromAxis: 10,
                                                alongAxis: 0,
                                            }}
                                            format={(date) => {
                                                return formatXAxis(date);
                                            }}
                                        />
                                    }
                                />
                            }
                        />
                    }
                /> */}
            </div>
        </div>
    );
}
