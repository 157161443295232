import React from "react";
import { CASTLE_ICON } from "../../consts";
import { FadeIn } from "../FadeIn";

// // // //

/**
 * Prevents the SwapCard from rendering until the VaultClient has been initialized
 */
export function LoadingBoundary(props: {
    loading: boolean;
    children: React.ReactNode;
}) {
    const { loading, children } = props;

    return (
        <React.Fragment>
            {loading && (
                <div className="max-w-3xl mx-auto flex-1 overflow-y-auto p-5 flex items-center justify-center">
                    <FadeIn show={loading}>
                        <div className="flex flex-col gap-4 items-center select-none">
                            <img src={CASTLE_ICON} className="h-16" />
                            <p className="text-xl font-semibold tracking-widest text-gray-300">
                                Castle Finance
                            </p>
                        </div>
                    </FadeIn>
                </div>
            )}

            <FadeIn show={!loading}>{children}</FadeIn>
        </React.Fragment>
    );
}
