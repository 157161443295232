import { FadeIn } from "../FadeIn";

// // // //

/**
 * LoadingSpinner
 * Renders a circular progress spinner to indicate that the content on the page is reloading
 * From: https://flowbite.com/docs/components/spinner/
 */
export function LoadingSpinner(props: {
    show?: boolean;
    size?: 3 | 4 | 6 | 8 | 10;
}) {
    const { show = true, size = 6 } = props;
    const heightCss = `h-${size} w-${size}`;

    return (
        <FadeIn show={show}>
            <svg
                className={`animate-spin text-white ${heightCss}`}
                role="status"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </FadeIn>
    );
}
