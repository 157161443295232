import { Cluster } from "@castlefinance/vault-core";
import React, { useEffect, useState } from "react";
import { getEnvironmentVars } from "../../config";
import { ClusterStatus } from "./types";

// // // //

// Defines default state for ClusterStatus
const EMPTY_CLUSTER_STATUS: ClusterStatus = {
    submitted: 0,
    confirmed: 0,
    loss: "",
    mean_ms: 0,
    ts: "",
    error_count: 0,
    error: "",
};

// Re-fetch every 30 seconds
const TIMEOUT = 30 * 1000;

// Pull API_GATEWAY from EnvironmentVars
const env = getEnvironmentVars();
const API_GATEWAY = env.apiGateway;

interface State {
    loading: boolean;
    error: boolean;
    status: ClusterStatus;
}

/**
 * ClusterStatusFetcher
 * Fetches stats via api.castle.finance
 */
export function ClusterStatusFetcher(props: {
    cluster: Cluster;
    children: (state: State) => React.ReactNode;
}) {
    const { cluster } = props;

    // Hook for tick state to re-fetch status periodically
    const [tick, setTick] = useState<number>(0);

    // Hook for loading + error + vaults state
    const [state, setState] = useState<State>({
        loading: true,
        error: false,
        status: EMPTY_CLUSTER_STATUS,
    });

    // Setup useEffect hook to request data on-mount
    // Implements fetch API invocation to fetch stats
    // Setup .then() + .catch() handlers to update component state
    useEffect(() => {
        fetch(`${API_GATEWAY}/clusters/${cluster}/status`)
            .then(async (res) => await res.json())
            .then((status) => {
                setState({
                    loading: false,
                    error: false,
                    status,
                });
            })
            .catch(() => {
                setState({
                    loading: false,
                    error: true,
                    status: EMPTY_CLUSTER_STATUS,
                });
            });
    }, [tick]);

    // Triggers re-fetch of cluster status
    useEffect(() => {
        setTimeout(() => {
            setTick(Date.now());
        }, TIMEOUT);
    }, [tick]);

    // Expose state via props.children
    return <React.Fragment>{props.children(state)}</React.Fragment>;
}
