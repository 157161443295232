import React from "react";

/**
 * Emdash
 * Renders an Emdash
 */
export function Emdash(props: { className?: string }) {
    if (props.className) {
        return <span className={props.className}>&mdash;</span>;
    }

    return <React.Fragment>&mdash;</React.Fragment>;
}
