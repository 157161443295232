import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import classnames from "classnames";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CastleApiProvider } from "../../components/CastleApiProvider";
import { FadeIn } from "../../components/FadeIn";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { notif } from "../../components/Notification";
import { PostTransactionMessage } from "../../components/PostTransactionMessage";
import { RefreshInterval } from "../../components/RefreshInterval";
import { TransactionsTable } from "../../components/TransactionsTable";
import { VaultDataFetcher } from "../../components/VaultDataFetcher";
import { VaultHistoryFetcher } from "../../components/VaultHistoryFetcher";
import { VaultIndividualFetcher } from "../../components/VaultIndividualFetcher";
import { VaultIndividualHeader } from "../../components/VaultIndividualHeader";
import { VaultWalletTransactionsFetcher } from "../../components/VaultWalletTransactionsFetcher";
import { getEnvironmentVars } from "../../config";
import {
    CASTLE_URLS,
    DEVNET_MARKET_URLS,
    MAINNET_MARKET_URLS,
    MarketUrls,
} from "../../consts";
import { Routes } from "../../routes";
import { ActionLayout } from "./ActionLayout";
import { AllocationDonutChart } from "./AllocationDonutChart";
import { AllocationsChart } from "../../components/AllocationsChart";
import { AllocationsSection } from "./AllocationsSection";
import { ApyChart } from "./ApyChart";
import { SectionWrapper } from "./SectionWrapper";
import { TotalValueChart } from "./TotalValueChart";
import { CurrentYieldSources } from "./YieldSource";

// // // //

/**
 * VaultIndividualLayout
 * Top-level layout for the VaultIndividual page
 */
export function VaultIndividualLayout(props: {
    vaultConfig: VaultConfig<DeploymentEnvs>;
    walletPublicKeyOverride?: string;
}) {
    const { vaultConfig, walletPublicKeyOverride } = props;

    // Filters vaults that match current DeploymentEnvironment
    const env = getEnvironmentVars();

    // Define marketURLs based on current DeploymentEnv
    const marketUrls: MarketUrls =
        env.deploymentEnv === DeploymentEnvs.mainnet
            ? MAINNET_MARKET_URLS
            : DEVNET_MARKET_URLS;

    return (
        <CastleApiProvider currentVault={vaultConfig}>
            {(castleApi) => {
                const walletPublicKey: string | null =
                    walletPublicKeyOverride || castleApi.walletPublicKey;

                // Refresh the VaultDataFetcher every 90 seconds...
                return (
                    <RefreshInterval interval={90000}>
                        {({ lastIntervalAt }) => (
                            <React.Fragment>
                                <PostTransactionMessage
                                    show={castleApi.showPostTransactionMessage}
                                />

                                <div className="flex justify-center">
                                    <div className="max-w-4xl lg:max-w-7xl px-4 py-16 sm:px-6 sm:pb-24 lg:px-8">
                                        <VaultDataFetcher
                                            vaultConfig={vaultConfig}
                                            lastTransactionTime={lastIntervalAt}
                                        >
                                            {({ loading, reloading, data }) => (
                                                <div
                                                    className={classnames(
                                                        "grid sm:grid-cols-1 gap-6",
                                                        {
                                                            "lg:grid-cols-3":
                                                                !walletPublicKeyOverride,
                                                            "lg:grid-cols-6":
                                                                walletPublicKeyOverride,
                                                        }
                                                    )}
                                                >
                                                    <div
                                                        className={classnames(
                                                            "flex flex-col items-center col-span-1",
                                                            {
                                                                "lg:col-span-2":
                                                                    !walletPublicKeyOverride,
                                                                "lg:col-span-4 lg:col-start-2":
                                                                    walletPublicKeyOverride,
                                                            }
                                                        )}
                                                    >
                                                        <VaultIndividualHeader
                                                            loading={loading}
                                                            reloading={
                                                                reloading
                                                            }
                                                            vaultConfig={
                                                                vaultConfig
                                                            }
                                                            apy={data.apy}
                                                            totalValueLocked={
                                                                data.value
                                                            }
                                                            totalVieldEarned={
                                                                data.totalYield
                                                            }
                                                            walletPublicKey={
                                                                walletPublicKey
                                                            }
                                                            lastTransactionTime={
                                                                castleApi.lastTransactionTime
                                                            }
                                                        />

                                                        {/* Only render ActionLayout when walletPublicKeyOverride isn't present */}
                                                        {!walletPublicKeyOverride && (
                                                            <div className="flex md:hidden mt-10">
                                                                <ActionLayout
                                                                    castleApi={
                                                                        castleApi
                                                                    }
                                                                />
                                                            </div>
                                                        )}

                                                        <CurrentYieldSources
                                                            marketUrls={
                                                                marketUrls
                                                            }
                                                            loading={loading}
                                                            solend={data.solend}
                                                            port={data.port}
                                                            jet={data.jet}
                                                            allocations={
                                                                data.allocations
                                                            }
                                                        />

                                                        <SectionWrapper
                                                            title="Allocations"
                                                            href={
                                                                CASTLE_URLS.docs
                                                            }
                                                        >
                                                            <AllocationsSection>
                                                                {loading && (
                                                                    <div
                                                                        className="flex items-center justify-center"
                                                                        style={{
                                                                            height: "22rem",
                                                                        }}
                                                                    >
                                                                        <LoadingSpinner
                                                                            size={
                                                                                8
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}

                                                                {!loading && (
                                                                    <AllocationDonutChart
                                                                        vaultData={
                                                                            data
                                                                        }
                                                                    />
                                                                )}
                                                            </AllocationsSection>
                                                        </SectionWrapper>

                                                        <VaultHistoryFetcher
                                                            vaultConfig={
                                                                vaultConfig
                                                            }
                                                        >
                                                            {({
                                                                history,
                                                                loading:
                                                                    loadingHistory,
                                                            }) => (
                                                                <React.Fragment>
                                                                    <SectionWrapper title="Aggregated APYs">
                                                                        {loadingHistory && (
                                                                            <div className="h-64 flex items-center justify-center">
                                                                                <LoadingSpinner />
                                                                            </div>
                                                                        )}

                                                                        {!loadingHistory && (
                                                                            <FadeIn
                                                                                show
                                                                            >
                                                                                <ApyChart
                                                                                    history={
                                                                                        history
                                                                                    }
                                                                                />
                                                                            </FadeIn>
                                                                        )}
                                                                    </SectionWrapper>

                                                                    <SectionWrapper title="Total Value Locked">
                                                                        {loadingHistory && (
                                                                            <div className="h-64 flex items-center justify-center">
                                                                                <LoadingSpinner />
                                                                            </div>
                                                                        )}

                                                                        {!loadingHistory && (
                                                                            <FadeIn
                                                                                show
                                                                            >
                                                                                <TotalValueChart
                                                                                    history={
                                                                                        history
                                                                                    }
                                                                                />
                                                                            </FadeIn>
                                                                        )}
                                                                    </SectionWrapper>

                                                                    <SectionWrapper title="Allocations">
                                                                        {loadingHistory && (
                                                                            <div className="h-64 flex items-center justify-center">
                                                                                <LoadingSpinner />
                                                                            </div>
                                                                        )}

                                                                        {!loadingHistory && (
                                                                            <FadeIn
                                                                                show
                                                                            >
                                                                                <AllocationsChart
                                                                                    history={
                                                                                        history
                                                                                    }
                                                                                />
                                                                            </FadeIn>
                                                                        )}
                                                                    </SectionWrapper>
                                                                </React.Fragment>
                                                            )}
                                                        </VaultHistoryFetcher>

                                                        {/* Render Transaction History IFF wallet is connected */}
                                                        {walletPublicKey && (
                                                            <SectionWrapper title="Your Transaction History">
                                                                <VaultWalletTransactionsFetcher
                                                                    vaultConfig={
                                                                        vaultConfig
                                                                    }
                                                                    lastTransactionTime={
                                                                        castleApi.lastTransactionTime
                                                                    }
                                                                    walletPublicKey={
                                                                        walletPublicKey
                                                                    }
                                                                >
                                                                    {({
                                                                        transactions,
                                                                        loading:
                                                                            loadingTransactions,
                                                                    }) => {
                                                                        if (
                                                                            loadingTransactions
                                                                        ) {
                                                                            return (
                                                                                <div className="h-64 flex items-center justify-center">
                                                                                    <LoadingSpinner />
                                                                                </div>
                                                                            );
                                                                        }

                                                                        return (
                                                                            <FadeIn
                                                                                show
                                                                            >
                                                                                <TransactionsTable
                                                                                    vaultConfig={
                                                                                        vaultConfig
                                                                                    }
                                                                                    transactions={
                                                                                        transactions
                                                                                    }
                                                                                />
                                                                            </FadeIn>
                                                                        );
                                                                    }}
                                                                </VaultWalletTransactionsFetcher>
                                                            </SectionWrapper>
                                                        )}
                                                    </div>

                                                    {/* Only render ActionLayout when walletPublicKeyOverride isn't present */}
                                                    {!walletPublicKeyOverride && (
                                                        <div className="hidden lg:flex flex-col items-center col-span-1">
                                                            <ActionLayout
                                                                castleApi={
                                                                    castleApi
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </VaultDataFetcher>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </RefreshInterval>
                );
            }}
        </CastleApiProvider>
    );
}

// // // //

/**
 * VaultIndividualPage
 * Displays the swap UI for an individual vault
 */
export function VaultIndividualPage() {
    // Pulls vaultID from path params
    const { vaultID } = useParams();
    const navigate = useNavigate();

    return (
        <VaultIndividualFetcher vaultID={String(vaultID)}>
            {(vaultFetcherState) => {
                const { loading, vaultConfig } = vaultFetcherState;

                // Render initial loading state
                if (loading) {
                    return (
                        <div className="py-96 flex justify-center">
                            <FadeIn show>
                                <LoadingSpinner />
                            </FadeIn>
                        </div>
                    );
                }

                if (vaultConfig === null && loading === false) {
                    // Redirect to VaultListing
                    navigate(Routes.home, { replace: true });

                    // Render toaster notification
                    notif({
                        type: "error",
                        message: "Vault not found - redirecting",
                    });
                    return null;
                }

                return (
                    <FadeIn show>
                        <VaultIndividualLayout
                            vaultConfig={
                                vaultConfig as VaultConfig<DeploymentEnvs>
                            }
                        />
                    </FadeIn>
                );
            }}
        </VaultIndividualFetcher>
    );
}
