import create from "zustand";
import * as anchor from "@project-serum/anchor";
import { getAllTokensAndSol } from "../utils/getAllTokensAndSol";
import { VaultClient } from "@castlefinance/vault-sdk";
import {
    CastleStore,
    TokenAccountData,
    TokenMap,
    TokenRegistryMap,
} from "./types";
import { getEnvironmentVars } from "../config";
import { CASTLE_TOKEN_REGISTRY } from "../consts";
import { PublicKey } from "@solana/web3.js";
import { getCurrentVault } from "./getCurrentVault";
import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import { USDC_TOKEN_REGISTRY } from "../consts";

// // // //

// Gets devnet/mainnet adapter
const env = getEnvironmentVars();

/**
 * Populates store.tokenMap default state
 */
export const useStore = create<CastleStore>((set) => {
    // Gets the current vault
    const currentVault: VaultConfig<DeploymentEnvs> = getCurrentVault();
    const vaultPublicKey = new PublicKey(currentVault.vault_id);

    // Define default state for store.tokenMap
    const defaultTokens = new Map<string, TokenAccountData | null>([
        [currentVault.token_mint, null],
    ]);

    // Define default state for store.tokenRegistryMap
    const DEFAULT_TOKEN_REGISTRY_MAP: TokenRegistryMap = {
        [currentVault.token_mint]: USDC_TOKEN_REGISTRY,
    };

    // Initialize default values
    return {
        // Current Anchor.Provider (default is null)
        provider: null,

        // The currently active VaultClient (default is null)
        vaultClient: null,

        // Stores state to display fallback if we
        // encounter initialization/load errors with VaultClient
        vaultLoadError: false,

        // Stores state to display fallback while loading balance of connected wallet
        loadingWalletBalances: false,

        // Initialize empty tokens
        tokenMap: defaultTokens,

        // Initialize TokenRegistryMap
        tokenRegistryMap: DEFAULT_TOKEN_REGISTRY_MAP,

        // Resets state.tokenMap
        // i.e. if someone disconnects their wallet -> sets clean state
        resetTokens: () => {
            set(() => ({
                tokenMap: defaultTokens,
            }));
        },

        // Refreshes vault + wallet state
        refresh: async (connection, wallet?) => {
            console.time("refreshing store");

            // Set loadingWalletBalances state = true
            set(() => ({
                loadingWalletBalances: true,
            }));

            const provider = new anchor.Provider(
                connection,
                wallet as unknown as anchor.Wallet,
                {
                    preflightCommitment: "confirmed",
                    commitment: "confirmed",
                }
            );
            anchor.setProvider(provider);

            // Loads up lending markets and ensures up-to-date
            // Wraps in try-catch in case Program can't be found on-chain
            let vaultClient: VaultClient | null = null;
            try {
                vaultClient = await VaultClient.load(
                    provider,
                    vaultPublicKey,
                    env.deploymentEnv
                );
            } catch (e) {
                console.log("VaultClient error:");
                console.log(e);

                // Set vaultLoadError to display fallback UI
                set(() => ({
                    vaultLoadError: true,
                }));
            }

            // Short-circuit remaining execution
            // if VaultClient never initialized due to an error
            if (vaultClient === null) {
                return;
            }

            // Gets PublicKey of the LP token mint
            const lpTokenMint = String(vaultClient.getLpTokenMint());

            // Defines TokenRegistryMap using lpTokenMint
            const tokenRegistryMap: TokenRegistryMap = {
                ...DEFAULT_TOKEN_REGISTRY_MAP,
                [lpTokenMint]: CASTLE_TOKEN_REGISTRY,
            };

            console.time("getting all tokens");

            let tokenAccounts: TokenMap;
            if (wallet?.publicKey) {
                tokenAccounts = await getAllTokensAndSol(
                    connection,
                    wallet.publicKey
                );
                set(() => ({
                    tokenMap: tokenAccounts,
                    vaultClient,
                    provider,
                    tokenRegistryMap,
                    vaultLoadError: false,
                    loadingWalletBalances: false,
                }));
            } else {
                set(() => ({
                    vaultClient,
                    provider,
                    tokenRegistryMap,
                    loadingWalletBalances: false,
                }));
            }
            console.timeEnd("getting all tokens");
            console.timeEnd("refreshing store");
        },
    };
});
