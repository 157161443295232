import { roundN } from "./roundN";

// // // //

/**
 * Formats APY value to 2 decimals
 * @param apy - non-percent number
 * @returns
 */
export function formatApy(apy: number): number {
    return roundN(apy * 100, 2);
}
