import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FadeIn } from "../FadeIn";

// // // //

/**
 * MEAN_MS_THRESHOLD
 * Defines the minimum meanMs value for the ClusterStatus UI to display
 */
const MEAN_MS_THRESHOLD = 25000;

/**
 * ClusterStatus
 * Displays a message that the Solana blockchain is experiencing slowdowns
 * @returns
 */
export function ClusterStatus(props: { meanMs: number }) {
    const { meanMs } = props;

    if (meanMs < MEAN_MS_THRESHOLD) {
        return null;
    }

    return (
        <FadeIn show>
            <div className="relative inset-x-0">
                <div className="p-2 bg-secondary-400 shadow-lg sm:p-3">
                    <div className="flex items-center justify-center flex-wrap max-w-7xl mx-auto">
                        <div className="flex items-center w-full justify-center">
                            <FontAwesomeIcon
                                className="h-4 w-4 text-white"
                                icon={faCircleExclamation}
                            />
                            <p className="ml-2 font-medium text-white truncate text-sm">
                                <span className="inline">
                                    The Solana blockchain is experiencing
                                    difficulties due to network congestion.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </FadeIn>
    );
}
