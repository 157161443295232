import castleLogo from "../../assets/lockup.svg";
import { WalletButton } from "../WalletButton";
import { DeploymentEnv, DeploymentEnvs } from "@castlefinance/vault-core";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";

// // // //

function NetworkBadge(props: { deploymentEnv: DeploymentEnv }) {
    const { deploymentEnv } = props;

    // Return devnet-staging badge
    if (deploymentEnv === DeploymentEnvs.devnetStaging) {
        return (
            <div className="select-none text-gray-100 flex items-center space-x-4 ml-6 font-semibold font-mono">
                DEVNET STAGING
            </div>
        );
    }

    // Return devnet badge
    if (deploymentEnv === DeploymentEnvs.devnetParity) {
        return (
            <div className="select-none text-gray-100 flex items-center space-x-4 ml-6 font-semibold font-mono">
                DEVNET
            </div>
        );
    }

    // Return null for mainnet
    return null;
}

// // // //

const navigation = [
    // { name: "Vaults", href: "https://app.castle.finance" },
    { name: "Research", href: "https://blog.castle.finance" },
    { name: "Docs", href: "https://docs.castle.finance" },
];

export function Navbar(props: {
    showWalletButton: boolean;
    deploymentEnv: DeploymentEnv;
    onRefresh: () => void;
}) {
    const { showWalletButton, deploymentEnv } = props;
    return (
        <Disclosure as="nav" className="bg-dark-200">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <MenuIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <a href={Routes.home}>
                                        <img
                                            className="block h-8 w-auto select-none"
                                            src={castleLogo}
                                            alt="Castle Finance Logo"
                                            draggable={false}
                                        />
                                    </a>
                                    <NetworkBadge
                                        deploymentEnv={deploymentEnv}
                                    />
                                </div>
                                <div className="hidden sm:block sm:ml-6 select-none">
                                    <div className="flex space-x-4">
                                        {navigation.map((item: any) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                target="_blank"
                                                rel="noreferrer"
                                                className={classnames(
                                                    item.current
                                                        ? "bg-gray-900 text-white"
                                                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                                    "px-3 py-2 rounded-md text-sm font-medium"
                                                )}
                                                aria-current={
                                                    item.current
                                                        ? "page"
                                                        : undefined
                                                }
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {showWalletButton && (
                                    <div className="ml-4 flex-shrink-0 hidden md:flex">
                                        <div className="shadow-lg select-none">
                                            <WalletButton
                                                size="sm"
                                                className="navbar-wallet-adapter-button"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item: any) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classnames(
                                        item.current
                                            ? "bg-gray-900 text-white"
                                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                        "block px-3 py-2 rounded-md text-base font-medium"
                                    )}
                                    aria-current={
                                        item.current ? "page" : undefined
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
