import { useEffect, useState } from "react";
import { useStore } from "../store";

// // // //

/**
 * Returns formatted lpExchangeRate
 */
export function useLpTokenExchangeRate(): number {
    const vaultClient = useStore((state) => state.vaultClient);
    const [exchangeRate, setExchangeRate] = useState<number>(1);

    useEffect(() => {
        const fetchExchangeRate = async () => {
            let updatedValue = 1;

            if (vaultClient) {
                updatedValue = (
                    await vaultClient.getLpExchangeRate()
                ).toNumber();
                setExchangeRate(updatedValue);
            }
        };
        fetchExchangeRate();
    }, [vaultClient]);

    return exchangeRate;
}
