import { PublicKey, TransactionSignature } from "@solana/web3.js";
import { Wallet, Provider } from "@project-serum/anchor";
import { VaultClient } from "@castlefinance/vault-sdk";

// // // //

/**
 * Deposit
 * Function to make a deposit into a vault
 * @param vaultClient - Singleton VaultClient from store/index.ts
 * @param provider - Global provider from store/index.ts
 * @param amount - the amount to deposit (# of tokens)
 * @param userReserveTokenAccount - the account from which the deposit is being made
 * @returns TransactionSignature
 */
export async function deposit(props: {
    vaultClient: VaultClient;
    provider: Provider;
    amount: number;
    userReserveTokenAccount: PublicKey;
    decimals: number;
}): Promise<TransactionSignature> {
    const { vaultClient, provider, amount, userReserveTokenAccount, decimals } =
        props;

    console.log(`Depositing: ${amount.toString()}`);

    // Invokes `deposit` method on VaultClient
    const sigs = await vaultClient.deposit(
        provider.wallet as Wallet,
        amount * 10 ** decimals,
        userReserveTokenAccount
    );

    // Pulls out the single transaction signature from the transaction
    const depositSig: TransactionSignature = sigs[0];

    // Returns the deposit string
    return depositSig;
}
