import arrowDownSrc from "../../assets/arrow-down.svg";
import arrowLeftSrc from "../../assets/arrow-left.svg";
import arrowRightSrc from "../../assets/arrow-right.svg";
import arrowUpSrc from "../../assets/arrow-up.svg";
import bellAlertSrc from "../../assets/bell-alert.svg";
import bellSrc from "../../assets/bell.svg";
import caretDownSrc from "../../assets/caret-down.svg";
import caretUpSrc from "../../assets/caret-up.svg";
import chamferedBox from "../../assets/chamfered-box.svg";
import checkSrc from "../../assets/check.svg";
import copySrc from "../../assets/copy.svg";
import fingerprintSrc from "../../assets/fingerprint.svg";
import jetSrc from "../../assets/jet.png";
import lifebuoySrc from "../../assets/lifebuoy.svg";
import linkToSrc from "../../assets/link-to.svg";
import portSrc from "../../assets/port.svg";
import solendSrc from "../../assets/solend.svg";
import swapSrc from "../../assets/swap.svg";
import timesSrc from "../../assets/times.svg";
import unlinkSrc from "../../assets/unlink.svg";
import walletSrc from "../../assets/wallet.svg";
import warningSrc from "../../assets/warning.svg";
import daoNativeSrc from "../../assets/dao-native.svg";
import customVaultsSrc from "../../assets/custom-vaults.svg";
import reportingSrc from "../../assets/reporting.svg";
import crossbarGreenSrc from "../../assets/crossbar-green.svg";
import crossbarPurpleSrc from "../../assets/crossbar-purple.svg";
import castleLogoSrc from "../../assets/castle-logo.svg";

// // // //

/**
 * Canonical list of all Icon types
 */
export enum IconTypes {
    arrowDown = "arrowDown",
    arrowLeft = "arrowLeft",
    arrowRight = "arrowRight",
    arrowUp = "arrowUp",
    bell = "bell",
    bellAlert = "bellAlert",
    caretDown = "caretDown",
    caretUp = "caretUp",
    chamferedBox = "chamferedBox",
    check = "check",
    copy = "copy",
    fingerprint = "fingerprint",
    jet = "jet",
    lifebuoy = "lifebuoy",
    linkTo = "linkTo",
    port = "port",
    solend = "solend",
    swap = "swap",
    times = "times",
    unlink = "unlink",
    wallet = "wallet",
    warning = "warning",
    daoNative = "daoNative",
    customVaults = "customVaults",
    reporting = "reporting",
    crossbarGreen = "crossbarGreen",
    crossbarPurple = "crossbarPurple",
    castleLogo = "castleLogo",
}
export type IconType = `${IconTypes}`;

/**
 * Ensures we have a tyoe-safe map of IconType -> SVG
 */
const mapIconTypeToSrc: { [key in IconTypes]: string } = {
    [IconTypes.arrowDown]: arrowDownSrc,
    [IconTypes.arrowLeft]: arrowLeftSrc,
    [IconTypes.arrowRight]: arrowRightSrc,
    [IconTypes.arrowUp]: arrowUpSrc,
    [IconTypes.bell]: bellSrc,
    [IconTypes.bellAlert]: bellAlertSrc,
    [IconTypes.caretDown]: caretDownSrc,
    [IconTypes.caretUp]: caretUpSrc,
    [IconTypes.chamferedBox]: chamferedBox,
    [IconTypes.check]: checkSrc,
    [IconTypes.copy]: copySrc,
    [IconTypes.fingerprint]: fingerprintSrc,
    [IconTypes.jet]: jetSrc,
    [IconTypes.lifebuoy]: lifebuoySrc,
    [IconTypes.linkTo]: linkToSrc,
    [IconTypes.port]: portSrc,
    [IconTypes.solend]: solendSrc,
    [IconTypes.swap]: swapSrc,
    [IconTypes.times]: timesSrc,
    [IconTypes.unlink]: unlinkSrc,
    [IconTypes.wallet]: walletSrc,
    [IconTypes.warning]: warningSrc,
    [IconTypes.daoNative]: daoNativeSrc,
    [IconTypes.customVaults]: customVaultsSrc,
    [IconTypes.reporting]: reportingSrc,
    [IconTypes.crossbarGreen]: crossbarGreenSrc,
    [IconTypes.crossbarPurple]: crossbarPurpleSrc,
    [IconTypes.castleLogo]: castleLogoSrc,
};

// // // //

interface Props {
    type: IconType;
    className?: string;
}

/**
 * Renders a Castle Finance Icon
 */
export function Icon(props: Props) {
    const { type, className } = props;
    const src = mapIconTypeToSrc[type];
    return (
        <img
            src={src}
            alt={`${type} Icon`}
            className={className}
            draggable={false}
        />
    );
}
