import React from "react";
import { Icon, IconType, IconTypes } from "../../components/Icon";
import { InlineButton } from "../../components/InlineButton";
import { InfoTooltip } from "../../components/Tooltip";

// // // //

/**
 * SectionWrapper
 * Renders a wrapper for a page section on the VaultIndividual page
 */
export function SectionWrapper(props: {
    title: string;
    tooltip?: string;
    href?: string;
    iconType?: IconType;
    children: React.ReactNode;
}) {
    const {
        title,
        tooltip,
        href,
        children,
        iconType = IconTypes.crossbarPurple,
    } = props;

    return (
        <div className="w-full mt-10 select-none border-t border-light-stroke pt-8">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Icon className="h-6" type={iconType} />
                    <p className="mx-3 text-2xl text-white font-medium">
                        {title}
                    </p>

                    {/* Conditionally render tooltip */}
                    {tooltip && (
                        <InfoTooltip message={tooltip} placement="right">
                            {({ setTriggerRef }) => (
                                <span ref={setTriggerRef} className="mx-3">
                                    <Icon
                                        className="h-4"
                                        type={IconTypes.warning}
                                    />
                                </span>
                            )}
                        </InfoTooltip>
                    )}
                </div>

                {/* Conditionally render InlineButton */}
                {href && (
                    <InlineButton
                        text="Learn More"
                        target="_blank"
                        className="mr-4 text-lg"
                        href={href}
                    />
                )}
            </div>

            <div className="mt-4">{children}</div>
        </div>
    );
}
