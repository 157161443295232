import { SwapControls } from "./SwapControls";
import { Action } from "../../store/types";
import { TokenMeta } from "../CastleApiProvider";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";

// // // //

function SwapInput(props: {
    title: string;
    tokenMeta: TokenMeta;
    disabled: boolean;
    value: number;
    loading: boolean;
    onChange: (updatedAmount: number) => void;
}) {
    const { title, tokenMeta, value, disabled, loading, onChange } = props;

    const [internalValue, setInternalValue] = useState<string>(
        value.toString()
    );

    useEffect(() => {
        setInternalValue(props.value.toString());
    }, [props.value]);

    return (
        <React.Fragment>
            <div className="flex justify-between items-center mb-3 mt-8">
                <div className="font-normal text-md text-white">{title}</div>
                <div className="flex items-center">
                    <LoadingSpinner show={loading} size={4} />
                    <div className="mr-1.5 ml-2 text-base text-gray-400">
                        Balance:{" "}
                        <span className="font-mono ml-1">
                            {tokenMeta.token?.uiAmountString || "0.00"}
                        </span>
                    </div>
                </div>
            </div>

            <div className="rounded-xl flex flex-col justify-around border border-gray-700">
                <div className="flex justify-between">
                    <div className="flex p-4">
                        <img
                            className="h-8 w-8"
                            draggable={false}
                            src={tokenMeta.icon}
                        />
                    </div>
                    <input
                        disabled={disabled}
                        className={classNames(
                            "text-lg block w-1/2 pr-4 text-right font-mono bg-transparent focus:outline-none no-underline select-none",
                            {
                                "cursor-not-allowed": disabled,
                            }
                        )}
                        type="number"
                        min={0.0}
                        step="0.01"
                        placeholder="0.00"
                        value={internalValue}
                        onBlur={(e) => {
                            onChange(parseFloat(e.currentTarget.value));
                        }}
                        onChange={(e) => {
                            setInternalValue(e.currentTarget.value);
                        }}
                    />
                    {!disabled && (
                        <button
                            className={classNames(
                                "px-6 py-3 font-semibold text-cf-green-100 hover:text-cf-green-200 border-l border-light-stroke",
                                {
                                    "cursor-not-allowed opacity-80": disabled,
                                }
                            )}
                            disabled={disabled}
                            onClick={() => {
                                const updatedValue = tokenMeta.token?.uiAmount
                                    ? tokenMeta.token?.uiAmount
                                    : 0;

                                // Set from amount to the TOTAL value in the current wallet
                                onChange(updatedValue);
                            }}
                        >
                            MAX
                        </button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

// TODO - ensure WITHDRAW + DEPOSIT UI cap to/from amount based on balance of corresponding wallet
export function SwapCard(props: {
    action: Action;
    from: TokenMeta;
    to: TokenMeta;
    selectDeposit: () => void;
    selectWithdraw: () => void;
    setFromAmount: (updatedFromAmount: number) => void;
    loading?: boolean;
    children?: React.ReactNode;
}): JSX.Element {
    const {
        action,
        from,
        to,
        selectDeposit,
        selectWithdraw,
        setFromAmount,
        loading = false,
    } = props;

    return (
        <div className="md:mx-auto sm:w-full md:max-w-md text-xl select-none bg-primary rounded-2xl border border-muted text-gray-100">
            <div className="p-6">
                {/* Deposit / Withdraw header buttons */}
                <SwapControls
                    action={action}
                    onClickDeposit={() => selectDeposit()}
                    onClickWithdraw={() => selectWithdraw()}
                />

                <div className="mt-4">
                    <SwapInput
                        title={`You ${action}`}
                        tokenMeta={from}
                        value={Number.isNaN(from.amount) ? 0 : from.amount}
                        onChange={setFromAmount}
                        disabled={false}
                        loading={loading}
                    />

                    <SwapInput
                        title={"You Receive"}
                        tokenMeta={to}
                        value={to.amount}
                        onChange={setFromAmount}
                        disabled={true}
                        loading={loading}
                    />
                </div>
            </div>

            <div className="border-t border-muted p-6">{props.children}</div>
        </div>
    );
}
