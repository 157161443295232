import React, { useEffect, useState } from "react";
import { getEnvironmentVars } from "../../config";

// // // //

// Pull API_GATEWAY from EnvironmentVars
const env = getEnvironmentVars();
const API_GATEWAY = env.apiGateway;

interface State {
    loading: boolean;
    error: boolean;
    totalValue: number;
    totalYield: number;
}

/**
 * StatsFetcher
 * Fetches stats via api.castle.finance
 */
export function StatsFetcher(props: {
    children: (state: State) => React.ReactNode;
}) {
    // Hook for loading + error + vaults state
    const [state, setState] = useState<State>({
        loading: true,
        error: false,
        totalValue: 0,
        totalYield: 0,
    });

    // Setup useEffect hook to request data on-mount
    // Implements fetch API invocation to fetch stats
    // Setup .then() + .catch() handlers to update component state
    useEffect(() => {
        fetch(`${API_GATEWAY}/vaults/stats`)
            .then(async (res) => await res.json())
            .then((stats) => {
                setState({
                    loading: false,
                    error: false,
                    totalValue: stats.totalValue,
                    totalYield: stats.totalYield,
                });
            })
            .catch(() => {
                setState({
                    loading: false,
                    error: true,
                    totalValue: 0,
                    totalYield: 0,
                });
            });
    }, []);

    // Expose state via props.children
    return <React.Fragment>{props.children(state)}</React.Fragment>;
}
