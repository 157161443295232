import { DeploymentEnv, DeploymentEnvs } from "@castlefinance/vault-core";

/**
 * Type union and enum for accepted process.env.NODE_ENV values
 */
export type NodeEnv = `${NodeEnvs}`;
export enum NodeEnvs {
    development = "development",
    production = "production",
}

/**
 * Interface to describe environment variables
 */
export interface EnvironmentVars {
    nodeEnv: NodeEnv;
    maintenanceMode: boolean;
    deploymentEnv: DeploymentEnv;
    apiGateway: string;
    datadogApplicationID: string;
    datadogClientToken: string;
}

/**
 * Returns EnvironmentVars instance
 */
export function getEnvironmentVars(): EnvironmentVars {
    // Gets value for EnvironmentVars.env
    // Throws exception if a non-standard environment is detected
    const env = process.env.NODE_ENV as NodeEnvs;
    if (![NodeEnvs.development, NodeEnvs.production].includes(env)) {
        throw new Error(`Unknown environment detected: ${env}`);
    }

    // Gets value for EnvironmentVars.deploymentEnv
    // Falls back to "devnet-staging" if process.env.REACT_APP_CASTLE_DEPLOYMENT_ENV isn't defined
    const deploymentEnv = (process.env.REACT_APP_CASTLE_DEPLOYMENT_ENV ||
        "devnet-staging") as DeploymentEnv;

    // Throw exception if deploymentEnv parsed from process.env is *not* valid
    if (
        ![
            DeploymentEnvs.devnetStaging,
            DeploymentEnvs.devnetParity,
            DeploymentEnvs.mainnet,
        ].includes(deploymentEnv as DeploymentEnvs)
    ) {
        throw new Error(
            `getEnvironmentVars - unsupported DeploymentEnv detected: ${deploymentEnv}`
        );
    }

    // Gets value for EnvironmentVars.maintenanceMode
    // Falls back to "false" if process.env.REACT_APP_CASTLE_MAINTENANCE_MODE isn't defined
    const maintenanceMode: boolean =
        process.env.REACT_APP_CASTLE_MAINTENANCE_MODE === "true" || false;

    // Determine apiGateway value
    // NOTE - ideally this should be pulled from an environment variable
    let apiGateway = "https://api-staging.castle.finance";
    if (deploymentEnv === DeploymentEnvs.mainnet) {
        apiGateway = "https://api.castle.finance";
    }

    // Pull Datadog applicationID + clientToken
    // Init Datadog RUM session in client-side only
    const datadogApplicationID =
        process.env.REACT_APP_DATADOG_APPLICATION_ID || "";
    const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "";

    // Returns application EnvironmentVars
    return {
        nodeEnv: env as NodeEnv,
        deploymentEnv,
        maintenanceMode,
        apiGateway,
        datadogApplicationID,
        datadogClientToken,
    };
}
