import classNames from "classnames";

// // // //

type ButtonVariant = `${ButtonVariants}`;
enum ButtonVariants {
    primary = "primary",
    primaryOutline = "primary-outline",
    secondary = "secondary",
    outline = "outline",
    success = "success",
    successOutline = "success-outline",
    whiteOutline = "white-outline",
}

type ButtonSize = `${ButtonSizes}`;
enum ButtonSizes {
    sm = "sm",
    md = "md",
    lg = "lg",
}

interface ButtonProps {
    text: React.ReactNode;
    disabled?: boolean;
    variant?: ButtonVariant;
    icon?: React.ReactNode;
    size?: ButtonSize;
    block?: boolean;
    testID?: string;
    className?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function getButtonClassName(props: {
    variant?: ButtonVariant;
    disabled?: boolean;
    size?: ButtonSize;
    block?: boolean;
    hasIcon?: boolean;
    className?: string;
}): string {
    const {
        variant = "primary",
        disabled = false,
        block = false,
        hasIcon = false,
        size = "lg",
        className,
    } = props;

    const isPrimary = variant === ButtonVariants.primary;
    const isPrimaryOutline = variant === ButtonVariants.primaryOutline;
    const isSecondary = variant === ButtonVariants.secondary;
    const isOutline = variant === ButtonVariants.outline;
    const isSuccess = variant === ButtonVariants.success;
    const isSuccessOutline = variant === ButtonVariants.successOutline;
    const isWhiteOutline = variant === ButtonVariants.whiteOutline;

    return classNames(
        className,
        "transition-all text-white flex items-center font-normal whitespace-nowrap hover:shadow-sm",
        {
            // Has Icon?
            "justify-between": hasIcon,
            "justify-center": !hasIcon,

            // Variant = Primary
            "bg-secondary-400": isPrimary,
            "hover:bg-secondary-450": isPrimary && !disabled,

            // Variant = PrimaryOutline
            "bg-transparent border border-secondary-400 text-secondary-400":
                isPrimaryOutline,
            "hover:bg-secondary-400 hover:text-white":
                isPrimaryOutline && !disabled,

            // Variant = Secondary
            "bg-secondary-100 border border-transparent": isSecondary,
            "hover:bg-secondary-150 hover:border-secondary-300":
                isSecondary && !disabled,

            // Variant = Outline
            "bg-transparent": isOutline,
            "hover:bg-light-gray hover:border-secondary-300":
                isOutline && !disabled,

            // Variant = Success
            "bg-status-green-100 text-gray-700": isSuccess,
            "hover:bg-status-green-150": isSuccess && !disabled,

            // Variant = SuccessOutline
            "bg-transparent border border-status-green-100 text-status-green-100":
                isSuccessOutline,
            "hover:bg-status-green-100 hover:text-gray-700":
                isSuccessOutline && !disabled,

            // Variant = WhiteOutline
            "bg-transparent border border-gray-100 text-gray-100":
                isWhiteOutline,
            "hover:bg-gray-100 hover:text-gray-700":
                isWhiteOutline && !disabled,

            // Disabled
            "cursor-not-allowed opacity-40": disabled,

            // Size
            "py-2 px-4 text-md rounded": size === "sm",
            "py-3 px-5 text-lg rounded": size === "md",
            "py-6 px-6 text-xl rounded-lg": size === "lg",

            // Block
            "w-full": block === true,
        }
    );
}

export function Button(props: ButtonProps): JSX.Element {
    const {
        text,
        disabled,
        onClick,
        variant = "primary",
        size = "lg",
        block = false,
        icon = null,
        testID,
        className,
    } = props;
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            data-testid={testID}
            className={getButtonClassName({
                variant,
                disabled,
                size,
                block,
                hasIcon: icon !== null,
                className,
            })}
        >
            {text}
            {icon}
        </button>
    );
}
