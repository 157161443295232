import { VaultData } from "./types";

// // // //

/**
 * Defines empty response for VaultDataFetcher
 */
export const EMPTY_VAULT_DATA: VaultData = {
    vaultID: "",
    value: 0,
    apy: 0,
    allocations: {
        reserve: 0,
        solend: 0,
        port: 0,
        jet: 0,
    },
    userValue: null,
    solend: {
        apy: 0,
        deposit: 0,
        borrow: 0,
    },
    port: {
        apy: 0,
        deposit: 0,
        borrow: 0,
    },
    jet: {
        apy: 0,
        deposit: 0,
        borrow: 0,
    },
    totalYield: 0,
};
