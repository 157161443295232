import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import { formatApy } from "../../utils/functions/formatApy";
import { FadeIn } from "../FadeIn";
import { Icon, IconTypes } from "../Icon/component";
import { LoadingSpinner } from "../LoadingSpinner";
import { OverviewValue } from "../OverviewValue";
import { VaultPublicKey } from "../VaultPublicKey";
import { VaultWalletBalanceFetcher } from "../VaultWalletBalanceFetcher";
import { UserPosition } from "./UserPosition";

// // // //

interface Props {
    loading: boolean;
    reloading: boolean;
    vaultConfig: VaultConfig<DeploymentEnvs>;
    walletPublicKey: null | string;
    lastTransactionTime?: number;
    apy: number;
    totalValueLocked: number;
    totalVieldEarned: number;
}

export function VaultIndividualHeader(props: Props) {
    const {
        loading,
        reloading,
        vaultConfig,
        walletPublicKey,
        lastTransactionTime,
        apy,
        totalValueLocked,
        totalVieldEarned,
    } = props;

    return (
        <div className="flex flex-col bg-mid-grey select-none bg-mid-grey border border-muted rounded-2xl bg-dark-100 w-full">
            <div className="flex flex-col md:flex-row p-6 md:p-10 gap-0 md:gap-6 rounded-2xl patterned-background">
                <div className="flex flex-col items-start w-full">
                    <div className="flex flex-row items-center">
                        <div className="flex min-w-6 w-6">
                            {reloading && <LoadingSpinner show size={6} />}
                            {!reloading && (
                                <FadeIn show>
                                    <Icon
                                        type={IconTypes.chamferedBox}
                                        className="h-6"
                                    />
                                </FadeIn>
                            )}
                        </div>
                        <h2 className="text-4xl font-bold text-white ml-4 mr-8">
                            ${vaultConfig.token_label}
                        </h2>
                        <div className="hidden md:flex">
                            <VaultPublicKey publicKey={vaultConfig.vault_id} />
                        </div>
                    </div>

                    <div className="flex-col justify-center flex md:hidden mt-4">
                        <VaultPublicKey publicKey={vaultConfig.vault_id} />
                    </div>

                    <p className="text-lg text-gray-300 mt-4">Castle Vault</p>

                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-col items-start">
                            <h2 className="text-2xl md:text-4xl font-bold text-white mt-3">
                                Risk Managed Max Yield
                            </h2>
                            <p className="text-lg text-gray-100 mt-3">
                                Automated active management of DeFi lending,
                                optimizing for maximal yield while minimizing
                                principal risk.
                            </p>
                        </div>
                        {walletPublicKey && (
                            // <div className="flex flex-col justify-center w-full md:w-1/2 xl:w-1/3 mt-8 md:mt-0">
                            <div className="flex flex-col justify-center w-full pl-0 md:pl-6 md:w-1/2 mt-8 md:mt-0">
                                <VaultWalletBalanceFetcher
                                    vaultConfig={vaultConfig}
                                    walletPublicKey={walletPublicKey}
                                    lastTransactionTime={lastTransactionTime}
                                >
                                    {({ loading, balance, error }) => {
                                        // Return null if there was an error
                                        if (error) {
                                            return null;
                                        }

                                        // Render UserPosition
                                        return (
                                            <UserPosition
                                                loading={loading}
                                                balance={balance}
                                            />
                                        );
                                    }}
                                </VaultWalletBalanceFetcher>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="px-4 py-8 flex flex-col lg:flex-row lg:items-center justify-center lg:divide-x divide-light-stroke flex-grow lg:flex-grow-0 space-y-6 lg:space-y-0 border-t border-light-stroke">
                <OverviewValue
                    className="flex-grow pl-6"
                    loading={loading}
                    value={`$${Math.floor(totalValueLocked).toLocaleString()}`}
                    label="Total Value Locked"
                />

                <OverviewValue
                    className="flex-grow pl-6"
                    loading={loading}
                    value={`${formatApy(apy)}%`}
                    label="Current APY"
                />

                <OverviewValue
                    className="flex-grow pl-6"
                    loading={loading}
                    value={`$${totalVieldEarned.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`}
                    label="Total Yield Earned"
                />
            </div>
        </div>
    );
}
