import { Transition } from "@headlessui/react";
import React from "react";

// // // //

type Duration = 75 | 100 | 150 | 200 | 300 | 500 | 700 | 1000;

/**
 * FadeIn
 * Implements a `@headlessui/react` transition to fade-in whatevers passed in via props.children
 */
export function FadeIn(props: {
    show?: boolean;
    duration?: Duration;
    children: React.ReactNode;
}) {
    const { show = true, duration = 500 } = props;
    return (
        <Transition
            appear={true}
            show={show}
            enter={`transition-opacity duration-${duration}`}
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave={`transition-opacity duration-${duration}`}
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {props.children}
        </Transition>
    );
}
