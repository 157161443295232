import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import React, { useEffect, useState } from "react";
import { getEnvironmentVars } from "../../config";
import { VaultTransaction } from "./types";

// // // //

// Pull API_GATEWAY from EnvironmentVars
const API_GATEWAY = getEnvironmentVars().apiGateway;

/**
 * VaultWalletTransactionsFetcher
 * Fetches Vault Wallet Action history via api.castle.finance
 */
export function VaultWalletTransactionsFetcher(props: {
    vaultConfig: VaultConfig<DeploymentEnvs>;
    walletPublicKey: string;
    lastTransactionTime?: number;
    children: (childProps: {
        loading: boolean;
        error: boolean;
        transactions: VaultTransaction[];
    }) => React.ReactNode;
}) {
    const { vaultConfig, lastTransactionTime } = props;

    // Hook for loading state + resulting VaultBalance
    // Initial state uses emtpy VaultBalance object for type-safety
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<VaultTransaction[]>([]);

    // Setup useEffect hook to request data on-mount
    // Implements fetch API invocation to fetch VaultBalance
    // Setup .then() + .catch() handlers to update component state
    useEffect(() => {
        fetch(
            `${API_GATEWAY}/vaults/${vaultConfig.vault_id}/wallet/${props.walletPublicKey}/actions`
        )
            .then(async (res) => await res.json())
            .then((resp) => {
                // Sort the actions -> latest transactions at the top
                const actions: VaultTransaction[] = resp?.actions || [];
                actions.sort((a, b) =>
                    new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1
                );
                setTransactions(actions);
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [vaultConfig.vault_id, lastTransactionTime]);

    // Expose state via props.children
    return (
        <React.Fragment>
            {props.children({ loading, error, transactions })}
        </React.Fragment>
    );
}
