import React, { useState } from "react";
import { Button } from "../../components/Button";
import { CastleApi } from "../../components/CastleApiProvider/types";
import { Icon, IconTypes } from "../../components/Icon";
import { Modal } from "../../components/Modal";
import { ReviewTransactionCard } from "../../components/ReviewTransactionCard";
import { SwapCard } from "../../components/Swap";
import { TransactionErrorCard } from "../../components/TransactionErrorCard";
import { TransactionSuccessCard } from "../../components/TransactionSuccessCard";
import { WalletButton } from "../../components/WalletButton";
import { Actions } from "../../store/types";

// // // //

const STEP_TIMEOUT = 1500;

/**
 * Displays layout for SwapCard + Modal behavior
 * All controlled via the CastleApi
 */
export function ActionLayout(props: { castleApi: CastleApi }) {
    const [loading, setLoading] = useState(false);
    const {
        step,
        setStep,
        action,
        to,
        from,
        confirming,
        onSubmit,
        onCancel,
        selectDeposit,
        selectWithdraw,
        setFromAmount,
        isWalletConnected,
        loadingWalletBalances,
        currentVault,
        txSignature,
    } = props.castleApi;

    const opts = {
        actionType: Actions.withdraw,
        actionAmountValue: from.amount,
        actionAmountSymbol: from.symbol,
        receiveAmountValue: to.amount,
        receiveAmountSymbol: to.symbol,
    };

    return (
        <React.Fragment>
            <SwapCard
                to={to}
                from={from}
                action={action}
                loading={loadingWalletBalances}
                selectDeposit={() => {
                    if (action !== Actions.deposit) {
                        selectDeposit();
                    }
                }}
                selectWithdraw={() => {
                    if (action !== Actions.withdraw) {
                        selectWithdraw();
                    }
                }}
                setFromAmount={(updatedFromAmount) => {
                    setFromAmount(updatedFromAmount);
                }}
            >
                {/* Render ConfirmTransaction button */}
                {isWalletConnected && (
                    <Button
                        block
                        size="lg"
                        testID="confirm-transaction"
                        text="Confirm Transaction"
                        disabled={!from.amount || !to.amount}
                        icon={
                            <Icon
                                type={IconTypes.fingerprint}
                                className="h-6"
                            />
                        }
                        onClick={() => {
                            setLoading(true);
                            setStep(1);
                            setTimeout(() => {
                                setLoading(false);
                            }, STEP_TIMEOUT);
                        }}
                    />
                )}

                {/* Render ConnectWallet button */}
                {!isWalletConnected && <WalletButton size="lg" />}
            </SwapCard>

            <Modal
                show={step === 1 || step === 2 || step === 3}
                onHide={() => onCancel()}
            >
                {step === 1 && (
                    <ReviewTransactionCard
                        actionType={action}
                        loading={loading}
                        confirming={confirming}
                        vaultLabel={currentVault.uiName}
                        actionAmountValue={opts.actionAmountValue}
                        actionAmountSymbol={opts.actionAmountSymbol}
                        receiveAmountValue={opts.receiveAmountValue}
                        receiveAmountSymbol={opts.receiveAmountSymbol}
                        onCancel={() => onCancel()}
                        onConfirm={() => {
                            onSubmit();
                        }}
                    />
                )}
                {step === 2 && (
                    <TransactionSuccessCard
                        actionType={action}
                        cluster={currentVault.cluster}
                        txSignature={txSignature}
                        vaultLabel={currentVault.uiName}
                        resultAmountValue={opts.actionAmountValue}
                        resultAmountSymbol={opts.actionAmountSymbol}
                        onDismiss={() => {
                            // Reset `fromAmount` + close modal
                            setFromAmount(0);
                            setStep(0);
                        }}
                    />
                )}
                {step === 3 && (
                    <TransactionErrorCard
                        actionType={action}
                        onDismiss={() => {
                            // Reset `fromAmount` + close modal
                            setFromAmount(0);
                            setStep(0);
                        }}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
}
