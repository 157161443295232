import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "./ErrorBoundary";
import { getEnvironmentVars } from "../config";
import { MaintenanceBoundary } from "./MaintenanceBoundary";
import { NotFound } from "../pages/NotFound";
import { VaultListPage } from "../pages/VaultList";
import { AppLayout } from "./AppLayout";
import { Routes as AppRoutes } from "../routes";
import { VaultIndividualPage } from "../pages/VaultIndividual";
import { VaultIndividualAsWalletPage } from "../pages/VaultIndividual/AsWallet";

// // // //

function App(): JSX.Element {
    const env = getEnvironmentVars();

    return (
        <BrowserRouter>
            <MaintenanceBoundary maintenanceMode={env.maintenanceMode}>
                <ErrorBoundary>
                    <Routes>
                        <Route path={AppRoutes.home} element={<AppLayout />}>
                            <Route
                                path={AppRoutes.home}
                                element={<VaultListPage />}
                            />
                            <Route
                                path={AppRoutes.vaultListing}
                                element={<VaultListPage />}
                            />
                            <Route
                                path={AppRoutes.vaultIndividual}
                                element={<VaultIndividualPage />}
                            />
                            <Route
                                path={AppRoutes.vaultIndividualAsWallet}
                                element={<VaultIndividualAsWalletPage />}
                            />
                        </Route>

                        {/* 404 */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>

                    <Toaster
                        position="bottom-left"
                        toastOptions={{
                            success: { duration: 10000 },
                        }}
                    />
                </ErrorBoundary>
            </MaintenanceBoundary>
        </BrowserRouter>
    );
}

export default App;
