import {
    ChartTooltip,
    ChartZoomPan,
    Gridline,
    GridlineSeries,
    Line,
    LinearAxisLine,
    LinearAxisTickLabel,
    LinearXAxis,
    LinearXAxisTickSeries,
    LinearYAxis,
    LinearYAxisTickSeries,
    LineChart,
    LineSeries,
    TooltipArea,
} from "reaviz";
import { VaultHistory } from "../../components/VaultHistoryFetcher/types";

// // // //

enum ValueLabels {
    tvl = "TVL",
}

const mapValueToColor: { [key in ValueLabels]: string } = {
    [ValueLabels.tvl]: "#4fff95",
};

/**
 * TooltipContent
 * Render content for the Chart tooltip
 */
function TooltipContent(props: {
    x: string;
    data: Array<{ key: ValueLabels; value: number; x: string }>;
}) {
    const { data } = props;

    return (
        <div className="flex flex-col w-48">
            <p className="text-lg mb-3">{formatDate(props.x)}</p>
            {data.map((d) => (
                <div key={d.key} className="flex justify-between">
                    <p className="flex items-center">
                        <span
                            className="h-3 w-3 rounded-full mr-2"
                            style={{ backgroundColor: mapValueToColor[d.key] }}
                        />
                        <span
                            className="mr-2"
                            style={{ color: mapValueToColor[d.key] }}
                        >
                            {d.key}
                        </span>
                    </p>
                    <p key={d.key} className="flex items-center">
                        <span
                            className="font-mono"
                            style={{ color: mapValueToColor[d.key] }}
                        >
                            ${Math.floor(d.value).toLocaleString()}
                        </span>
                    </p>
                </div>
            ))}
        </div>
    );
}

/**
 * formatDate
 * Formats date values for the chart
 */
function formatDate(date: unknown): string {
    if (!date) {
        return "";
    }

    return (date as Date).toLocaleString("en-us", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        timeZone: "utc",
    });
}

/**
 * formatXAxis
 * Function to format tick values for the x-axis
 */
function formatXAxis(date: unknown): string {
    if (!date) {
        return "";
    }

    return (date as Date).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        timeZone: "utc",
    });
}

/**
 * TotalValueChart
 * Renders TVL chart on VaultIndividual page
 */
export function TotalValueChart(props: { history: VaultHistory }) {
    const { history } = props;
    const data = [
        {
            key: ValueLabels.tvl,
            data: [
                ...Object.keys(history.value).map((k) => {
                    return {
                        key: new Date(k),
                        data: parseFloat(history.value[k]),
                    };
                }),
            ],
        },
    ];

    return (
        <div className="pl-16 mt-6 pr-4 md:pl-4 md:pr-4">
            <LineChart
                height={300}
                data={data}
                zoomPan={<ChartZoomPan />}
                gridlines={
                    <GridlineSeries
                        line={
                            <Gridline direction="all" strokeColor={"#222234"} />
                        }
                    />
                }
                series={
                    <LineSeries
                        tooltip={
                            <TooltipArea
                                placement="right"
                                height={300}
                                tooltip={
                                    <ChartTooltip
                                        className="rounded-xl border-4 border-dark-100 p-4"
                                        content={(value: any) => {
                                            return (
                                                <TooltipContent
                                                    x={value.x}
                                                    data={value.data}
                                                />
                                            );
                                        }}
                                    />
                                }
                            />
                        }
                        type="grouped"
                        line={
                            <Line
                                strokeWidth={1}
                                style={(data: any) => {
                                    if (
                                        data &&
                                        data.length &&
                                        data[0] &&
                                        data[0].key !== ValueLabels.tvl
                                    ) {
                                        return {
                                            strokeDasharray: "10",
                                        };
                                    }
                                    return {
                                        strokeWidth: "3",
                                    };
                                }}
                            />
                        }
                        colorScheme={(data: any) => {
                            return mapValueToColor[data[0].key as ValueLabels];
                        }}
                    />
                }
                yAxis={
                    <LinearYAxis
                        scaled={true}
                        type="value"
                        roundDomains
                        axisLine={<LinearAxisLine />}
                        tickSeries={
                            <LinearYAxisTickSeries
                                label={
                                    <LinearAxisTickLabel
                                        text="TVL"
                                        rotation={0}
                                        format={(value) => {
                                            return `$${value.toLocaleString()}`;
                                        }}
                                        fontSize={14}
                                        position="start"
                                        padding={{
                                            fromAxis: 10,
                                            alongAxis: 0,
                                        }}
                                    />
                                }
                            />
                        }
                    />
                }
                xAxis={
                    <LinearXAxis
                        type="time"
                        scaled={true}
                        axisLine={<LinearAxisLine strokeWidth={1} />}
                        tickSeries={
                            <LinearXAxisTickSeries
                                label={
                                    <LinearAxisTickLabel
                                        text="Date"
                                        fontSize={14}
                                        position="end"
                                        padding={{
                                            fromAxis: 10,
                                            alongAxis: 0,
                                        }}
                                        format={(date) => {
                                            return formatXAxis(date);
                                        }}
                                    />
                                }
                            />
                        }
                    />
                }
            />
        </div>
    );
}
