import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { VaultTransaction } from "../VaultWalletTransactionsFetcher";
import { getExplorerUrl } from "../../utils/getExplorerUrl";

// // // //

/**
 * mapActionTypeToLabel
 * Maps VaultTransaction.action to a human-readable value
 */
const mapActionTypeToLabel = {
    deposit: "Deposit",
    withdrawal: "Withdraw",
};

/**
 * formatTimestamp
 * Formats VaultTransaction.timestamp
 */
function formatTimestamp(date: string): string {
    return new Date(date).toLocaleString("utc", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        timeZone: "utc",
    });
}

/**
 * TransactionsTable
 * Renders a list of a Wallet's transactions for a specific VaultConfig
 */
export function TransactionsTable(props: {
    transactions: VaultTransaction[];
    vaultConfig: VaultConfig<DeploymentEnvs>;
}) {
    const { transactions, vaultConfig } = props;

    const { token_label } = vaultConfig;
    return (
        <div className="mt-6 w-full ring-1 ring-light-stroke sm:-mx-6 md:mx-0 md:rounded-lg select-none">
            <table className="min-w-full divide-y divide-light-stroke">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-200 sm:pl-6"
                        >
                            Type
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-200 lg:table-cell"
                        >
                            Amount
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-200 lg:table-cell"
                        >
                            Timestamp
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-200 lg:table-cell"
                        >
                            Signature
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((action, idx) => (
                        <tr key={action.txSignature}>
                            <td
                                className={classnames(
                                    "pl-6 pr-3 py-3.5 text-sm text-gray-200 table-cell",
                                    {
                                        "border-t border-light-stroke":
                                            idx === 0,
                                    }
                                )}
                            >
                                <div className="font-medium text-gray-100">
                                    {mapActionTypeToLabel[action.type]}
                                </div>
                            </td>
                            <td
                                className={classnames(
                                    "px-3 py-3.5 text-sm text-gray-200 table-cell",
                                    {
                                        "border-t border-light-stroke":
                                            idx === 0,
                                    }
                                )}
                            >
                                <span className="mr-2">{action.amount}</span>
                                {token_label}
                            </td>
                            <td
                                className={classnames(
                                    "px-3 py-3.5 text-sm text-gray-200 table-cell",
                                    {
                                        "border-t border-light-stroke":
                                            idx === 0,
                                    }
                                )}
                            >
                                {formatTimestamp(action.timestamp)}
                                <span className="text-xs tracking-widest font-extralight ml-1.5 text-gray-400">
                                    UTC
                                </span>
                            </td>
                            <td
                                className={classnames(
                                    "hidden px-3 py-3.5 text-sm text-gray-200 lg:table-cell",
                                    {
                                        "border-t border-light-stroke":
                                            idx === 0,
                                    }
                                )}
                            >
                                <a
                                    href={getExplorerUrl({
                                        txSignature: action.txSignature,
                                        cluster: vaultConfig.cluster,
                                    })}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-indigo-300 hover:text-indigo-400 flex items-center"
                                >
                                    <div className="truncate w-32 font-mono">
                                        {action.txSignature}
                                    </div>
                                    <ExternalLinkIcon className="h-5 w-5 pb-1 ml-2 inline-block" />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {transactions.length === 0 && (
                <div className="text-center flex items-center justify-center w-full pt-4 pb-6 text-yellow-500">
                    No Transaction History
                </div>
            )}
        </div>
    );
}
