/**
 * getExplorerUrl
 * Generate URL for explorer.solana.com that links to the Transaction
 * @param txSignature
 * @returns
 */
export function getExplorerUrl(props: {
    txSignature: string;
    cluster: string;
}): string {
    const { txSignature, cluster } = props;

    // Construct URL
    const url = `https://explorer.solana.com/tx/${txSignature}`;

    // Add `cluster=devnet` parameter for devenet vaults
    if (cluster === "devnet") {
        return `${url}?cluster=devnet`;
    }

    // Return mainnet URL
    return url;
}
