import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import React from "react";
import { FadeIn } from "../../components/FadeIn";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { OverviewHeader } from "../../components/OverviewHeader";
import { StatsFetcher } from "../../components/StatsFetcher";
import {
    MAP_TOKEN_TO_ICON,
    VaultCard,
    VaultCardList,
    VaultPosition,
} from "../../components/VaultCard/component";
import { VaultDataFetcher } from "../../components/VaultDataFetcher";
import { VaultListFetcher } from "../../components/VaultListFetcher";
import { CASTLE_URLS, SOL_ICON } from "../../consts";
import { useConnectedWallet } from "../../hooks/useConnectedWallet";
import { roundN } from "../../utils/functions/roundN";
import { VaultListGroupHeader } from "./VaultListGroupHeader";

// // // //

/**
 * VaultListPage
 * Page that list all available Vaults
 */
export function VaultListPage() {
    // Get public key from connected wallet
    const connectedWallet = useConnectedWallet();
    const publicKey: string | null =
        connectedWallet?.publicKey?.toString() || null;

    return (
        <div className="flex flex-col items-center">
            <StatsFetcher>
                {(stats) => (
                    <OverviewHeader
                        loading={stats.loading}
                        totalValue={stats.totalValue}
                        totalYield={stats.totalYield}
                    />
                )}
            </StatsFetcher>

            <VaultListFetcher>
                {({ loading, maxYieldVaults, equalAllocationVaults }) => {
                    // Render LoadingSpinner when fetching VaultConfigs
                    if (loading) {
                        return (
                            <div className="h-80 py-64 flex items-center justify-center">
                                <FadeIn show>
                                    <LoadingSpinner />
                                </FadeIn>
                            </div>
                        );
                    }

                    return (
                        <FadeIn show>
                            <div className="w-full max-w-4xl lg:max-w-7xl px-4 pb-16 sm:px-6 lg:px-8">
                                {/* Render MaxYield Vaults */}
                                {maxYieldVaults.length > 0 && (
                                    <VaultListGroup
                                        title="Max Yield Vaults"
                                        vaults={maxYieldVaults}
                                        publicKey={publicKey}
                                    />
                                )}

                                {/* Render EqualAllocation Vaults */}
                                {equalAllocationVaults.length > 0 && (
                                    <VaultListGroup
                                        title="Equal Allocation Vaults"
                                        vaults={maxYieldVaults}
                                        publicKey={publicKey}
                                    />
                                )}
                            </div>
                        </FadeIn>
                    );
                }}
            </VaultListFetcher>
        </div>
    );
}

// // // //

/**
 * Renders a grouped list of Vaults (i.e. "Max Yield" or "Equal Allocation")
 */
function VaultListGroup(props: {
    title: string;
    vaults: VaultConfig<DeploymentEnvs>[];
    publicKey: string | null;
}) {
    const { title, vaults, publicKey } = props;

    return (
        <React.Fragment>
            <VaultListGroupHeader label={title} href={CASTLE_URLS.docs} />
            <VaultCardList>
                {vaults.map((v) => (
                    <VaultDataFetcher vaultConfig={v} key={v.vault_id}>
                        {({ loading, data }) => (
                            <VaultCard
                                className={
                                    vaults.length === 1
                                        ? "col-start-1 lg:col-start-2"
                                        : undefined
                                }
                                href={`/vaults/${v.vault_id}`}
                                loading={loading}
                                tokenLabel={v.token_label}
                                tokenIcon={
                                    MAP_TOKEN_TO_ICON[v.token_label] || SOL_ICON
                                }
                                // TODO - map this content from StrategyType
                                strategyLabel={"Risk Managed Max Yield"}
                                strategyDescription={
                                    "Automated active management of DeFi lending, optimizing for maximal yield while minimizing principal risk."
                                }
                                currentApy={roundN(data.apy, 5)}
                                currentApyPercentChange={0.093}
                                totalValueUsd={roundN(data.value, 5)}
                                totalValueToken={roundN(data.value, 5)}
                                portYieldPercent={data.port.apy}
                                jetYieldPercent={data.jet.apy}
                                solendYieldPercent={data.solend.apy}
                            >
                                {publicKey !== null && (
                                    <VaultPosition
                                        vaultConfig={v}
                                        publicKey={publicKey}
                                    />
                                )}
                            </VaultCard>
                        )}
                    </VaultDataFetcher>
                ))}
            </VaultCardList>
        </React.Fragment>
    );
}
