import {
    WalletModalProvider,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import classnames from "classnames";
import { CASTLE_ICON } from "../../consts";
import { getButtonClassName } from "../Button/component";
import { Icon, IconTypes } from "../Icon";

// // // //

/**
 * Renders customized "Select Wallet" button
 */
export function WalletButton(props: {
    size?: "sm" | "lg";
    className?: string;
}) {
    const { size = "lg", className } = props;

    return (
        <WalletModalProvider logo={CASTLE_ICON}>
            <WalletMultiButton
                className={getButtonClassName({ block: true, size, className })}
                endIcon={
                    <Icon
                        type={IconTypes.wallet}
                        className={classnames({
                            "h-4 ml-4": size === "sm",
                            "h-6 ml-6": size === "lg",
                        })}
                    />
                }
            />
        </WalletModalProvider>
    );
}
