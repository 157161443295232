import { XIcon } from "@heroicons/react/solid";
import { Action, Actions } from "../../store/types";
import { TransferCard } from "../TransferCard/component";

// // // //

interface Props {
    actionType: Action;
    onDismiss: () => void;
}

const mapActionTypeToLabel = {
    [Actions.deposit]: "Deposit",
    [Actions.withdraw]: "Withdraw",
};

export function TransactionErrorCard(props: Props) {
    const { actionType, onDismiss } = props;

    const actionLabel = mapActionTypeToLabel[actionType];
    return (
        <TransferCard
            label={`${actionLabel} Unsuccessful`}
            onDismiss={() => onDismiss()}
        >
            <div className="p-6 text-center">
                <p className="text-white font-semibold">
                    Oops! Something went wrong with your transaction.
                </p>
                <p className="text-white font-light mt-4">
                    Please try again later.
                </p>
            </div>

            <div className="border-t border-light-stroke p-6">
                <button
                    onClick={() => {
                        onDismiss();
                    }}
                    className="bg-transparent hover:bg-light-stroke border border-light-stroke transition-colors text-white flex items-center justify-between py-6 px-8 text-xl font-semibold rounded-xl w-full disabled:opacity-70 disabled:cursor-not-allowed"
                >
                    <span>Back to Vault</span>
                    <XIcon className="h-6" />
                </button>
            </div>
        </TransferCard>
    );
}
