import {
    Connection,
    Keypair,
    PublicKey,
    LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { NATIVE_MINT } from "@solana/spl-token";
import { TokenAccountData, TokenMap } from "../store/types";
import { calculateATA } from "./calculateATA";
import { getSplTokenAccounts } from "./getSplTokenAccounts";

// // // //

/**
 * Gets all SPL Associated Token Accounts, including SOL
 * @param connection
 * @param publicKey
 * @returns
 */
export async function getAllTokensAndSol(
    connection: Connection,
    publicKey: PublicKey
): Promise<TokenMap> {
    // Grab all token accounts
    const tokenAccounts = await getSplTokenAccounts(connection, publicKey);

    // Calculate all ATAs for each mint
    // NOTE - ATA = "Associated Token Account"
    // Doc: https://spl.solana.com/associated-token-account
    // Made by creating a hash of the token mint with the user's public key
    // Makes a unique identifier for a user's account with a particular mint
    const atas_ = (
        await Promise.all(
            tokenAccounts.map((a) =>
                calculateATA(new PublicKey(a.mint), publicKey)
            )
        )
    ).map((a) => a.toString());

    // Filter out all token accounts that are not ATAs
    const atas = tokenAccounts.filter((acct) =>
        atas_.includes(acct.account.toString())
    );

    // Create the token map with all ATAs
    const tokenMap: TokenMap = new Map<string, TokenAccountData | null>();
    atas.forEach((acct) => {
        tokenMap.set(acct.mint.toString(), acct);
    });

    // Get SOL balance
    const solBalance = await connection.getBalance(publicKey);
    const solAccount: TokenAccountData = {
        account: Keypair.generate().publicKey,
        mint: NATIVE_MINT,
        owner: publicKey,
        decimals: 9,
        uiAmount: solBalance / LAMPORTS_PER_SOL,
        uiAmountString: solBalance.toString(),
    };

    // include SOL balance
    tokenMap.set(NATIVE_MINT.toString(), solAccount);

    return tokenMap;
}
