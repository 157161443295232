import {
    ChartTooltip,
    ChartZoomPan,
    DiscreteLegend,
    DiscreteLegendEntry,
    Gridline,
    GridlineSeries,
    Line,
    LinearAxisLine,
    LinearAxisTickLabel,
    LinearXAxis,
    LinearXAxisTickSeries,
    LinearYAxis,
    LinearYAxisTickSeries,
    LineChart,
    LineSeries,
    TooltipArea,
} from "reaviz";
import { VaultHistory } from "../../components/VaultHistoryFetcher/types";

// // // //

/**
 * Defines enum of labels for APY chart
 */
export enum ApyLabels {
    castle = "Castle",
    solend = "Solend",
    jet = "Jet",
    port = "Port",
}

/**
 * Map each APY to its corresponding color
 */
export const mapApyToColor: { [key in ApyLabels]: string } = {
    [ApyLabels.castle]: "#4fff95",
    [ApyLabels.solend]: "#ff5c28",
    [ApyLabels.jet]: "#7BCBCD",
    [ApyLabels.port]: "#826DF9",
};

/**
 * Defines default sort order for APY data
 */
const sortOrder = [
    ApyLabels.castle,
    ApyLabels.port,
    ApyLabels.jet,
    ApyLabels.solend,
];

/**
 * TooltipContent
 * Render content for chart tooltip
 */
function TooltipContent(props: {
    x: string;
    data: Array<{ key: ApyLabels; value: number; x: string }>;
}) {
    // Sorts `Castle` to the top
    const data: Array<{ key: ApyLabels; value: number; x: string }> =
        props.data.sort((a, b) =>
            sortOrder.indexOf(a.key) > sortOrder.indexOf(b.key) ? 1 : -1
        );

    return (
        <div className="flex flex-col w-48">
            <p className="text-lg mb-3">{formatDate(props.x)}</p>
            {data.map((d) => (
                <div key={d.key} className="flex justify-between">
                    <p className="flex items-center">
                        <span
                            className="h-3 w-3 rounded-full mr-2"
                            style={{ backgroundColor: mapApyToColor[d.key] }}
                        />
                        <span
                            className="mr-2"
                            style={{ color: mapApyToColor[d.key] }}
                        >
                            {d.key}
                        </span>
                    </p>
                    <p key={d.key} className="flex items-center">
                        <span
                            className="font-mono"
                            style={{ color: mapApyToColor[d.key] }}
                        >
                            {d.value.toFixed(7)}%
                        </span>
                    </p>
                </div>
            ))}
        </div>
    );
}

/**
 * formatDate
 * Formats date values for the chart
 */
function formatDate(date: unknown) {
    if (!date) {
        return "";
    }

    return (date as Date).toLocaleString("en-us", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        timeZone: "utc",
    });
}

/**
 * formatXAxis
 * Function to format tick values for the x-axis
 */
function formatXAxis(date: unknown) {
    if (!date) {
        return "";
    }

    return (date as Date).toLocaleString("en-us", {
        month: "numeric",
        day: "numeric",
        timeZone: "utc",
    });
}

/**
 * ApyChart
 * Renders chart for historical APY
 * @param props
 * @returns
 */
export function ApyChart(props: { history: VaultHistory }) {
    const { history } = props;

    const data = [
        {
            key: ApyLabels.castle,
            data: [
                ...Object.keys(history.apy).map((k) => {
                    return {
                        key: new Date(k),
                        data: parseFloat(history.apy[k]) * 100,
                    };
                }),
            ],
        },
        {
            key: ApyLabels.solend,
            data: [
                ...Object.keys(history.solendApy).map((k) => {
                    return {
                        key: new Date(k),
                        data: parseFloat(history.solendApy[k]) * 100,
                    };
                }),
            ],
        },
        {
            key: ApyLabels.jet,
            data: [
                ...Object.keys(history.jetApy).map((k) => {
                    return {
                        key: new Date(k),
                        data: parseFloat(history.jetApy[k]) * 100,
                    };
                }),
            ],
        },
        {
            key: ApyLabels.port,
            data: [
                ...Object.keys(history.portApy).map((k) => {
                    return {
                        key: new Date(k),
                        data: parseFloat(history.portApy[k]) * 100,
                    };
                }),
            ],
        },
    ];

    return (
        <div className="mt-4">
            <DiscreteLegend
                orientation="horizontal"
                className="pb-6 px-12 w-full"
                entries={[
                    <DiscreteLegendEntry
                        key={ApyLabels.castle}
                        label={ApyLabels.castle}
                        color={mapApyToColor[ApyLabels.castle]}
                    />,
                    <DiscreteLegendEntry
                        key={ApyLabels.jet}
                        label={ApyLabels.jet}
                        color={mapApyToColor[ApyLabels.jet]}
                    />,
                    <DiscreteLegendEntry
                        key={ApyLabels.port}
                        label={ApyLabels.port}
                        color={mapApyToColor[ApyLabels.port]}
                    />,
                    <DiscreteLegendEntry
                        key={ApyLabels.solend}
                        label={ApyLabels.solend}
                        color={mapApyToColor[ApyLabels.solend]}
                    />,
                ]}
            />
            <div className="pl-6 pr-4 md:pl-4 md:pr-4">
                <LineChart
                    height={300}
                    data={data}
                    zoomPan={<ChartZoomPan />}
                    gridlines={
                        <GridlineSeries
                            line={
                                <Gridline
                                    direction="all"
                                    strokeColor={"#222234"}
                                />
                            }
                        />
                    }
                    series={
                        <LineSeries
                            tooltip={
                                <TooltipArea
                                    placement="right"
                                    height={300}
                                    tooltip={
                                        <ChartTooltip
                                            className="rounded-xl border-4 border-dark-100 p-4"
                                            content={(value: any) => {
                                                return (
                                                    <TooltipContent
                                                        x={value.x}
                                                        data={value.data}
                                                    />
                                                );
                                            }}
                                        />
                                    }
                                />
                            }
                            type="grouped"
                            line={
                                <Line
                                    strokeWidth={1}
                                    style={(data: any) => {
                                        if (
                                            data &&
                                            data.length &&
                                            data[0] &&
                                            data[0].key !== ApyLabels.castle
                                        ) {
                                            return {
                                                strokeDasharray: "10",
                                            };
                                        }
                                        return {
                                            strokeWidth: "3",
                                        };
                                    }}
                                />
                            }
                            colorScheme={(data: any) => {
                                return mapApyToColor[data[0].key as ApyLabels];
                            }}
                        />
                    }
                    yAxis={
                        <LinearYAxis
                            scaled={true}
                            type="value"
                            axisLine={<LinearAxisLine />}
                            roundDomains
                            domain={[0, 15]}
                            tickSeries={
                                <LinearYAxisTickSeries
                                    label={
                                        <LinearAxisTickLabel
                                            rotation={0}
                                            text="Percent"
                                            format={(percent) => {
                                                return `${percent}%`;
                                            }}
                                            fontSize={14}
                                            position="start"
                                            padding={{
                                                fromAxis: 10,
                                                alongAxis: 0,
                                            }}
                                        />
                                    }
                                />
                            }
                        />
                    }
                    xAxis={
                        <LinearXAxis
                            type="time"
                            scaled={false}
                            axisLine={<LinearAxisLine strokeWidth={1} />}
                            tickSeries={
                                <LinearXAxisTickSeries
                                    label={
                                        <LinearAxisTickLabel
                                            text="Date"
                                            fontSize={14}
                                            position="end"
                                            padding={{
                                                fromAxis: 10,
                                                alongAxis: 0,
                                            }}
                                            format={(date) => {
                                                return formatXAxis(date);
                                            }}
                                        />
                                    }
                                />
                            }
                        />
                    }
                />
            </div>
        </div>
    );
}
