import { roundN } from "../../utils/functions/roundN";
import { useStore } from "../../store";
import { useConnectedWallet } from "../../hooks/useConnectedWallet";
import React, { useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { withdraw } from "../../utils/withdraw";
import { deposit } from "../../utils/deposit";
import { notif } from "../Notification";
import { useLpTokenExchangeRate } from "../../hooks/useLpTokenExchangeRate";
import { PublicKey, TransactionSignature } from "@solana/web3.js";
import { Action, Actions } from "../../store/types";
import { CastleApi } from "./types";
import { CASTLE_TOKEN_REGISTRY } from "../../consts";
import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";

// // // //

/**
 * Encapsulates the high-level state + actions for the application
 * State + methods are passed down via props.children
 */
export function CastleApiProvider(props: {
    currentVault: VaultConfig<DeploymentEnvs>;
    children: (childProps: CastleApi) => React.ReactNode;
}) {
    const { currentVault } = props;
    const wallet = useWallet();
    const lpExchangeRate = useLpTokenExchangeRate();
    const { connection } = useConnection();
    const connectedWallet = useConnectedWallet();

    const [action, setAction] = useState<Action>(Actions.deposit);
    const [txSignature, setTxSignature] = useState<string | null>(null);
    const [lastRefresh, setLastRefresh] = useState<number>(0);
    const [showPostTransactionMessage, setShowPostTransactionMessage] =
        useState<boolean>(false);
    const [step, setStep] = useState(0);
    const [confirming, setConfirming] = useState<boolean>(false);
    const [fromMint, setFromMint] = useState<PublicKey | null>(
        new PublicKey(currentVault.token_mint)
    );
    const [toMint, setToMint] = useState<PublicKey | null>(null);
    const [fromAmount, setFromAmount] = useState<number>(0);

    // Function to swap which mint public keys are being used
    function swapMints() {
        const prevToMint = toMint;
        const prevFromMint = fromMint;
        setToMint(prevFromMint);
        setFromMint(prevToMint);
    }

    // Pull state + methods from store
    const provider = useStore((state) => state.provider);
    const vaultClient = useStore((state) => state.vaultClient);
    const refresh = useStore((state) => state.refresh);
    const tokenAccounts = useStore((state) => state.tokenMap);
    const resetTokens = useStore((state) => state.resetTokens);
    const tokenRegistryMap = useStore((state) => state.tokenRegistryMap);
    const vaultLoadError = useStore((state) => state.vaultLoadError);
    const loadingWalletBalances = useStore(
        (state) => state.loadingWalletBalances
    );

    // Refresh state when connectedd / connectedWallet change
    useEffect(() => {
        if (connectedWallet && connection) {
            refresh(connection, connectedWallet);
        } else if (connection) {
            refresh(connection);
            resetTokens();
        }
    }, [connectedWallet, connection, lastRefresh]);

    // // // //

    useEffect(() => {
        setTimeout(() => {
            setLastRefresh(Number(new Date()));
        }, 30000); // Every 30 seconds...

        // // // //
        // The function returned from useEffect is executed when this component is unmounted
        // return () => {
        //     mountedComponent.current = false;
        // };
    }, [lastRefresh]);

    // // // //

    // // // //
    // // // //
    // NOTE - all this stuff was hoisted up from Swap.tsx
    // This must bs cleaned up and simplified!

    // Build toMintString + fromMintString
    const fromMintString: string = fromMint?.toString() || "";
    const filteredKeys: string[] = Object.keys(tokenRegistryMap).filter(
        (k) => k !== fromMintString
    );
    const toMintString = toMint?.toString() || filteredKeys[0] || "";

    // Calculate toAmount
    // NOTE - this MAYBE should be done in the store
    // TODO - break this out into a separate function
    const toAmount =
        action === Actions.deposit
            ? roundN(fromAmount / lpExchangeRate, 5)
            : roundN(fromAmount * lpExchangeRate, 5);

    // NOTE - this SHOULD be handled in the store!
    // NOTE - this SHOULD be handled in the store!
    const [fromToken, setFromToken] = useState(
        tokenAccounts.get(fromMintString)
    );

    // NOTE - this SHOULD be handled in the store!
    // NOTE - this SHOULD be handled in the store!
    const [toToken, setToToken] = useState(tokenAccounts.get(toMintString));

    // Track the time of the last transaction (defaults to 0)
    // Used to trigger a re-fetch of UserPosition + UserTransactions
    const [lastTransactionTime, setLastTransactionTime] = useState(0);

    // Sert fromToken & toToken when fromMint / toMint / tokenAccounts change
    useEffect(() => {
        setFromToken(tokenAccounts.get(fromMintString));
        setToToken(tokenAccounts.get(toMintString));
        // Set "toMint" state if it's not already set
        // NOTE - this is **JANK** AF and it must be fixed.
        if (toMint === null && toMintString !== "") {
            setToMint(new PublicKey(toMintString));
        }
    }, [fromMint, toMint, tokenAccounts]);

    // Ensure `fromAmount` is less than or equal to the value of `fromToken.uiAmount`,
    useEffect(() => {
        // Short-circuit if fromToken is not defined
        if (!fromToken) {
            return;
        }

        // If `fromAmount` is greater than `fromToken.uiAmount`,
        // set `fromAmount` to the `fromToken.uiAmount` value (the maximum)
        if (fromAmount > fromToken.uiAmount) {
            setFromAmount(fromToken.uiAmount);
        }
    }, [fromToken]);

    // Get the token symbols and icons from the store
    const fromTokenRegistry =
        tokenRegistryMap[fromMintString] || CASTLE_TOKEN_REGISTRY;
    const fromSymbol = fromTokenRegistry.symbol;
    const fromIcon = fromTokenRegistry.icon;

    const toTokenRegistry =
        tokenRegistryMap[toMintString] || CASTLE_TOKEN_REGISTRY;
    const toSymbol = toTokenRegistry.symbol;
    const toIcon = toTokenRegistry.icon;

    // Used for action button
    const stableSymbol: string | undefined =
        tokenRegistryMap[currentVault.token_mint]?.symbol;

    // Callback for dispatching a deposit or withdrawal action
    async function onSubmit() {
        console.log("ON SUBMIT!!!");

        // If fromAmount is less than 0...
        if (fromAmount <= 0) {
            // Display notification
            notif({
                type: "info",
                message: "Please enter an amount greater than 0",
            });
            return;
        }
        // Short-circuit if requisite state isn't present
        // OR fromAmount is invalid
        if (
            !provider ||
            !wallet.adapter?.publicKey ||
            !vaultClient ||
            fromAmount <= 0
        ) {
            return;
        }

        // Set `confirming` state
        setConfirming(true);

        // QUESTION - do we want to set a maximum limit in the UI?
        // TODO - if action isn't deposit/withdraw -> short-circuit function execution

        // Variable to store the signature returned by the deposit/withdraw methods
        let sig: TransactionSignature | null = null;

        if (action === Actions.deposit) {
            // Display notification
            notif({ type: "info", message: "Depositing..." });

            // Attempt to execute deposit
            try {
                // Reserve Token = type of token user is depositing
                // This is the public key of "token account" corresponding to the desired reserve token
                const reserveAccount =
                    await vaultClient.getUserReserveTokenAccount(
                        wallet.adapter.publicKey
                    );

                // Invokes deposit function
                sig = await deposit({
                    vaultClient,
                    provider,
                    amount: fromAmount,
                    userReserveTokenAccount: reserveAccount,
                    decimals: currentVault.token_decimals,
                });

                // Set txSignature state
                setTxSignature(sig);

                // Display success notification
                notif({
                    type: "success",
                    message: "Transaction succeeded.",
                    txId: sig,
                });
                setStep(2);
                setConfirming(false);
                // Set post-transaction state
                setShowPostTransactionMessage(true);
            } catch (error: any) {
                // Display error notification
                notif({ type: "error", message: error.message });
                setStep(3);
                setConfirming(false);
            }
        } else if (action === Actions.withdraw) {
            try {
                // Invokes withdraw function
                sig = await withdraw({
                    vaultClient,
                    provider,
                    amount: fromAmount,
                    decimals: currentVault.token_decimals,
                });

                // Display success notification
                notif({
                    type: "success",
                    message: "Transaction succeeded.",
                    txId: sig,
                });
                setStep(2);
                setConfirming(false);
                // Set post-transaction state
                setShowPostTransactionMessage(true);
            } catch (error: any) {
                // Display error notification
                notif({ type: "error", message: error.message });
                setStep(3);
                setConfirming(false);
            }
        }

        // Refreshing wallet/connection state
        refresh(connection, wallet);

        // Wait a lil bit before triggering a refetch of user position + transactions
        setTimeout(() => {
            // Trigger refresh
            setLastTransactionTime(Date.now());

            // Clear post-transaction state flag
            setShowPostTransactionMessage(false);
        }, 30000);
    }

    // // // //
    // // // //

    return (
        <React.Fragment>
            {props.children({
                action,
                onSubmit,
                vaultClient,
                onCancel: () => {
                    if (confirming) {
                        return;
                    }
                    setStep(0);
                    setConfirming(false);
                },
                selectDeposit: () => {
                    if (action === Actions.deposit) {
                        return;
                    }
                    setAction(Actions.deposit);
                    swapMints();
                },
                selectWithdraw: () => {
                    if (action === Actions.withdraw) {
                        return;
                    }
                    setAction(Actions.withdraw);
                    swapMints();
                },
                refresh: () => {
                    refresh(connection, wallet);
                },
                to: {
                    amount: toAmount,
                    icon: toIcon,
                    symbol: toSymbol,
                    token: toToken,
                },
                from: {
                    amount: fromAmount,
                    icon: fromIcon,
                    symbol: fromSymbol,
                    token: fromToken,
                },
                stableSymbol,
                setFromAmount: (updatedFromAmount: number) => {
                    setFromAmount(updatedFromAmount);
                },
                isWalletConnected: wallet.publicKey !== null,
                walletPublicKey: wallet.publicKey?.toString() || null,
                vaultLoadError,
                loadingWalletBalances,
                confirming,
                setConfirming,
                step,
                setStep: (updatedStep: number) => {
                    // Clear txSignature when action modal closes
                    if (updatedStep === 0) {
                        setTxSignature(null);
                    }
                    setStep(updatedStep);
                },
                currentVault,
                lastTransactionTime,
                txSignature,
                showPostTransactionMessage,
            })}
        </React.Fragment>
    );
}
