import { ExternalLinkIcon } from "@heroicons/react/outline";
import React from "react";
import { DotDotDots } from "../../components/DotDotDots";
import { Icon, IconType, IconTypes } from "../../components/Icon";
import { Emdash } from "../../components/Punctuation";
import {
    Allocations,
    AssetData,
} from "../../components/VaultDataFetcher/types";
import { CASTLE_URLS, MarketUrls } from "../../consts";
import { formatApy } from "../../utils/functions/formatApy";
import { SectionWrapper } from "./SectionWrapper";

// // // //

/**
 * YieldSource
 * Card for a single YieldSource
 */
export function YieldSource(props: {
    icon: IconType;
    label: string;
    loading: boolean;
    externalLink: string;
    audited?: boolean;
    yieldPercent: number;
    openSource?: boolean;
    allocation: number;
}) {
    const {
        icon,
        label,
        loading,
        allocation,
        externalLink,
        yieldPercent,
        audited = false,
        openSource = false,
    } = props;

    // Skip rendering if there is no money allocated to this Yield Source
    if (loading === false && allocation == 0) {
        return null;
    }

    return (
        <div className="rounded-lg bg-mid-gray p-6 flex-grow">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <Icon type={icon} className="h-6" />
                    <p className="ml-4 text-lg font-medium">
                        {loading && (
                            <React.Fragment>
                                {label} @ <DotDotDots />
                            </React.Fragment>
                        )}

                        {!loading && yieldPercent === 0 && (
                            <React.Fragment>
                                {label} @ <Emdash />
                            </React.Fragment>
                        )}

                        {!loading && yieldPercent !== 0 && (
                            <React.Fragment>
                                {label} @ {formatApy(yieldPercent)}%
                            </React.Fragment>
                        )}
                    </p>
                </div>
                <a
                    href={externalLink}
                    target="_blank"
                    rel="noreferrer"
                    className="flex text-gray-200 hover:text-white transition-colors"
                >
                    <ExternalLinkIcon className="h-5 w-5 inline-block" />
                </a>
            </div>
            <div className="flex gap-3 mt-6 flex-wrap">
                {audited && <Badge label="Audited" />}
                {openSource && <Badge label="Open-Sourced" />}
            </div>
        </div>
    );
}

/**
 * Badge
 * Badge for YieldSource card
 */
function Badge(props: { label: string }) {
    return (
        <div className="bg-light-gray text-white flex flex-grow justify-center rounded-lg text-sm p-2 whitespace-nowrap">
            {props.label}
        </div>
    );
}

/**
 * CurrentYieldSources
 * Renders CurrentYieldSources section of VaultIndividual page
 */
export function CurrentYieldSources(props: {
    loading: boolean;
    marketUrls: MarketUrls;
    allocations: Allocations;
    solend: AssetData;
    port: AssetData;
    jet: AssetData;
}) {
    const { marketUrls, allocations, loading, port, jet, solend } = props;
    return (
        <SectionWrapper title="Current Yield Sources" href={CASTLE_URLS.docs}>
            <div className="flex flex-col md:flex-row gap-6">
                <YieldSource
                    icon={IconTypes.port}
                    label="Port"
                    externalLink={marketUrls.port}
                    audited
                    openSource
                    loading={loading}
                    yieldPercent={port.apy}
                    allocation={allocations.port}
                />
                {/* <YieldSource
                    icon={IconTypes.jet}
                    label="Jet"
                    externalLink={marketUrls.jet}
                    audited
                    openSource
                    loading={loading}
                    yieldPercent={jet.apy}
                    allocation={allocations.jet}
                /> */}
                <YieldSource
                    icon={IconTypes.solend}
                    label="Solend"
                    externalLink={marketUrls.solend}
                    audited
                    openSource
                    loading={loading}
                    yieldPercent={solend.apy}
                    allocation={allocations.solend}
                />
            </div>
        </SectionWrapper>
    );
}
