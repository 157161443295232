import { CASTLE_ICON } from "../../consts";
import { FadeIn } from "../../components/FadeIn";
import { Link } from "react-router-dom";

// // // //

/**
 * NotFound
 * Our 404 page
 */
export function NotFound() {
    return (
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-dark-100">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <FadeIn show={true}>
                    <div className="flex flex-col gap-4 items-center select-none">
                        <img src={CASTLE_ICON} className="h-16" />
                    </div>

                    <div className="pb-16 pt-6">
                        <div className="text-center">
                            <h1 className="text-4xl font-extrabold text-gray-100 tracking-tight sm:text-5xl">
                                Page not found.
                            </h1>
                            <p className="mt-6 text-xl text-gray-100">
                                {
                                    "Sorry, we couldn't find the page you're looking for."
                                }
                            </p>
                            <div className="mt-6">
                                <Link
                                    to="/"
                                    className="text-base font-medium text-status-green-100 hover:text-status-green-150 transition-colors"
                                >
                                    Go back home
                                    <span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </FadeIn>
            </main>
        </div>
    );
}
