import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { EnvironmentVars } from "../config";

// // // //

/**
 * getWalletAdapterNetwork
 * Returns the WalletAdapterNetwork (Devnet or Mainnet)
 * NOTE - `Cluster` from "@solana/web3.js" is one-to-one with `WalletAdapterNetwork` and is what we use in the SDK
 * @param props.env - @see EnvironmentVars
 */
export function getWalletAdapterNetwork(props: {
    env: EnvironmentVars;
}): WalletAdapterNetwork.Devnet | WalletAdapterNetwork.Mainnet {
    const { env } = props;
    // Return devnet if env.network doesn't precisely equal `mainnet-beta`
    if (env.deploymentEnv !== "mainnet") {
        return WalletAdapterNetwork.Devnet;
    }

    // Otherwise, return Mainnet
    return WalletAdapterNetwork.Mainnet;
}
