import "react-popper-tooltip/dist/styles.css";
import * as PopperJS from "@popperjs/core";
import { usePopperTooltip } from "react-popper-tooltip";
import React from "react";
import classnames from "classnames";
import { FadeIn } from "../FadeIn";
import styles from "./styles.module.css";

// // // //

interface Props {
    placement?: PopperJS.Placement;
    children: (childProps: {
        setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    }) => React.ReactNode;
    tooltipContent: React.ReactNode;
    tooltipWrapperClassName?: string;
}

/**
 * Generic, mostly-unstyled Tooltip component
 * Doc: https://github.com/mohsinulhaq/react-popper-tooltip
 */
export function Tooltip(props: Props) {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({ placement: props.placement });

    return (
        <React.Fragment>
            <React.Fragment>{props.children({ setTriggerRef })}</React.Fragment>
            <FadeIn show={visible}>
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: classnames(
                            "tooltip-container",
                            styles.tooltipContainerDark,
                            props.tooltipWrapperClassName
                        ),
                    })}
                >
                    <div
                        {...getArrowProps({
                            className: classnames(
                                "tooltip-arrow",
                                "border-light-stroke"
                            ),
                        })}
                    />
                    {props.tooltipContent}
                </div>
            </FadeIn>
        </React.Fragment>
    );
}
