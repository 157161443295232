import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faGithub,
    faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { CASTLE_URLS } from "../../consts";

// // // //

/**
 * Renders Icon link to social media sites
 */
function SocialLink(props: {
    href: string;
    label: string;
    icon: IconDefinition;
}) {
    const { href, label, icon } = props;
    return (
        <a
            href={href}
            target="_blank"
            aria-label={label}
            className="text-gray-200 hover:text-secondary-400 transition-colors"
            rel="noreferrer"
            draggable={false}
        >
            <FontAwesomeIcon className="h-6" icon={icon} />
        </a>
    );
}

/**
 * Renders a single link to misc. external sites
 */
function FooterLink(props: { href: string; label: string }) {
    const { href, label } = props;
    return (
        <a
            href={href}
            target="_blank"
            aria-label={label}
            className="text-gray-100 hover:text-white transition-colors"
            rel="noreferrer"
            draggable={false}
        >
            {label}
        </a>
    );
}

/**
 * Renders links to FAQs / Blog / Terms / Policy
 */
function FooterLinks() {
    return (
        <div className="flex space-x-6 pb-4 md:pb-0">
            <FooterLink label="Research" href="https://blog.castle.finance/" />
            <FooterLink label="Docs" href="https://docs.castle.finance/" />
            <FooterLink
                label="Terms"
                href="https://www.castle.finance/terms-of-use"
            />
            {/* <FooterLink label="Policy" href="https://castle.finance" /> */}
        </div>
    );
}

/**
 * Renders "All Rights Reserved" notice
 */
function CompanyInfo() {
    return (
        <p className="text-gray-100 flex items-center pb-4 md:pb-0">
            <FontAwesomeIcon className="h-4 mr-2" icon={faCopyright} />
            Castle Labs, Inc. All Rights Reserved.
        </p>
    );
}

// // // //

export function Footer() {
    return (
        <div className="bg-dark-200 select-none">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center justify-between py-5">
                <CompanyInfo />
                <FooterLinks />
                <div className="flex space-x-6">
                    <SocialLink
                        icon={faTwitter}
                        label="Link to Castle Finance Twitter"
                        href={CASTLE_URLS.twitter}
                    />
                    <SocialLink
                        icon={faDiscord}
                        label="Link to Castle Finance Discord"
                        href={CASTLE_URLS.discord}
                    />
                    <SocialLink
                        icon={faGithub}
                        label="Link to Castle Finance GitHub"
                        href={CASTLE_URLS.github}
                    />
                </div>
            </div>
        </div>
    );
}
