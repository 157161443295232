import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import { useNavigate, useParams } from "react-router-dom";
import { FadeIn } from "../../components/FadeIn";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { notif } from "../../components/Notification";
import { VaultIndividualFetcher } from "../../components/VaultIndividualFetcher";
import { Routes } from "../../routes";
import { VaultIndividualLayout } from "./component";

// // // //

/**
 * VaultIndividualAsWalletPage
 * Displays the swap UI for an individual vault
 */
export function VaultIndividualAsWalletPage() {
    // Pulls vaultID from path params
    const { vaultID, walletID } = useParams();
    const navigate = useNavigate();

    return (
        <VaultIndividualFetcher vaultID={String(vaultID)}>
            {(vaultFetcherState) => {
                const { loading, vaultConfig } = vaultFetcherState;

                // Render initial loading state
                if (loading) {
                    return (
                        <div className="py-96 flex justify-center">
                            <FadeIn show>
                                <LoadingSpinner />
                            </FadeIn>
                        </div>
                    );
                }

                if (
                    (vaultConfig === null && loading === false) ||
                    walletID === undefined
                ) {
                    // Redirect to VaultListing
                    navigate(Routes.home, { replace: true });

                    // Render toaster notification
                    notif({
                        type: "error",
                        message: "Vault not found - redirecting",
                    });
                    return null;
                }

                return (
                    <FadeIn show>
                        <VaultIndividualLayout
                            walletPublicKeyOverride={walletID}
                            vaultConfig={
                                vaultConfig as VaultConfig<DeploymentEnvs>
                            }
                        />
                    </FadeIn>
                );
            }}
        </VaultIndividualFetcher>
    );
}
