import { Connection, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { TokenAccountData } from "../store/types";

// // // //

/**
 * Gets an array of user's SPL TokenAccountData
 * ALL SPL accounts, not exclusively ATAs
 * @param connection
 * @param publicKey
 * @returns
 */
export async function getSplTokenAccounts(
    connection: Connection,
    publicKey: PublicKey
): Promise<TokenAccountData[]> {
    // Get all tokens in someone's account
    const rpcResponse = await connection.getParsedTokenAccountsByOwner(
        publicKey,
        {
            programId: TOKEN_PROGRAM_ID,
        }
    );

    // Parse only relevant information and the token's symbol
    const parsedAccounts = rpcResponse.value.map((acct) => {
        return {
            account: acct.pubkey,
            mint: acct.account.data.parsed.info.mint,
            owner: acct.account.data.parsed.info.owner,
            // amount: acct.account.data.parsed.info.tokenAmount.amount,
            uiAmount: acct.account.data.parsed.info.tokenAmount.uiAmount,
            uiAmountString:
                acct.account.data.parsed.info.tokenAmount.uiAmountString,
            decimals: acct.account.data.parsed.info.tokenAmount.decimals,
        };
    });

    return parsedAccounts;
}
