import {
    MAINNET_VAULTS,
    DEVNET_PARITY_VAULTS,
    DeploymentEnvs,
    DEVNET_STAGING_VAULTS,
    VaultConfig,
    ALL_VAULTS,
} from "@castlefinance/vault-core";
import { getEnvironmentVars } from "../config";

// // // //

/**
 * getCurrentVault
 * NOTE - this is a stop-gap solution until we have vault-switching fully integrated
 */
export function getCurrentVault(): VaultConfig<DeploymentEnvs> {
    // Little bit of handwaving to get the ID of the current vault from the URL
    // Otherwise, we default to standard behavior
    const vaultID = window.location.pathname.split("/vaults/")[1];
    if (vaultID !== undefined) {
        const vaultConfig = ALL_VAULTS.find((v) => v.vault_id === vaultID);
        if (vaultConfig !== undefined) {
            return vaultConfig;
        }
    }

    const env = getEnvironmentVars();

    if (env.deploymentEnv === DeploymentEnvs.mainnet) {
        return MAINNET_VAULTS[0];
    }

    if (env.deploymentEnv === DeploymentEnvs.devnetParity) {
        return DEVNET_PARITY_VAULTS[0];
    }

    return DEVNET_STAGING_VAULTS[0];
}
