import { useEffect } from "react";
import { ErrorBoundary as ErrorBoundaryLib } from "react-error-boundary";
import { CASTLE_URLS } from "../../consts";
import { Button } from "../Button";
import { Modal } from "../Modal/component";

// // // //

/**
 * Renders user-facing fallback when an uncaught exception has occurred
 */
export function ErrorFallback() {
    // Refresh page after 3.5s
    useEffect(() => {
        setTimeout(() => {
            window.location.assign("/");
        }, 3500);
    }, []);

    return (
        <Modal show={true}>
            <div className="bg-gray-900 p-5 sm:p-6">
                <div className="sm:flex sm:items-start mb-5">
                    <div className="mt-3 text-center sm:mt-0 select-none">
                        <h3
                            className="text-xl leading-6 font-medium text-gray-200"
                            id="modal-headline"
                        >
                            Uh oh - something went wrong
                        </h3>
                        <div className="mt-3">
                            <p className="text-md text-gray-200">
                                {"Don't worry - the team is on it."}
                            </p>
                            <p className="text-md text-gray-200 font-light">
                                We apologize for any inconvenience - please
                                refresh the page and try again or contact
                                support{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={CASTLE_URLS.twitter}
                                    className="text-indigo-500 hover:text-indigo-600"
                                >
                                    @castlefinance
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                </div>

                <Button
                    text="Refresh"
                    block
                    size="sm"
                    onClick={() => {
                        // Reloads the page
                        window.location.assign("/");
                    }}
                />
            </div>
        </Modal>
    );
}

/**
 * Renders an Error Boundary for the React App
 * Doc: https://reactjs.org/docs/error-boundaries.html
 */
export function ErrorBoundary(props: { children: React.ReactNode }) {
    return (
        <ErrorBoundaryLib FallbackComponent={ErrorFallback}>
            {props.children}
        </ErrorBoundaryLib>
    );
}
