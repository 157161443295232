import classNames from "classnames";
import React from "react";
import { CASTLE_ICON } from "../../consts";
import { FadeIn } from "../FadeIn";

// // // //

/**
 * Prevents application UI from rendering when the app is in maintenance-mode - see getEnvironmentVars for more details.
 */
export function MaintenanceBoundary(props: {
    maintenanceMode: boolean;
    children: React.ReactNode;
}) {
    const { maintenanceMode, children } = props;

    // Pass-thru props.children if maintenanceMode is off
    if (maintenanceMode === false) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    // Render maintenance-mode boundary
    return (
        <div
            className={classNames(
                "max-w-3xl mx-auto flex-1 overflow-y-auto p-5 flex items-center justify-center"
            )}
        >
            <FadeIn show={true}>
                <div className="flex flex-col gap-4 items-center select-none">
                    <img src={CASTLE_ICON} className="h-16" />
                    <p className="text-xl font-semibold tracking-widest text-gray-300">
                        Castle Finance
                    </p>
                </div>

                <div className="flex flex-col gap-4 items-center select-none mt-16">
                    <p className="text-xl font-medium tracking-wide text-blue-300">
                        Maintenance Mode
                    </p>

                    <p className="text-lg font-light text-blue-300">
                        {
                            "Pardon our appearance - we're in the process of making some updates."
                        }
                    </p>

                    <p className="text-lg font-light text-blue-300">
                        Please check back shortly.
                    </p>
                </div>
            </FadeIn>
        </div>
    );
}
