import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import React, { useEffect, useState } from "react";
import { getEnvironmentVars } from "../../config";

// // // //

// Pull API_GATEWAY from EnvironmentVars
const API_GATEWAY = getEnvironmentVars().apiGateway;

/**
 * VaultWalletBalanceFetcher
 * Fetches Vault Wallet Balance via api.castle.finance
 */
export function VaultWalletBalanceFetcher(props: {
    vaultConfig: VaultConfig<DeploymentEnvs>;
    walletPublicKey: string;
    lastTransactionTime?: number;
    children: (childProps: {
        loading: boolean;
        error: boolean;
        balance: number;
    }) => React.ReactNode;
}) {
    const { vaultConfig, lastTransactionTime } = props;

    // Hook for loading state + resulting VaultBalance
    // Initial state uses emtpy VaultBalance object for type-safety
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [balance, setBalance] = useState<number>(0);

    // Setup useEffect hook to request data on-mount
    // Implements fetch API invocation to fetch VaultBalance
    // Setup .then() + .catch() handlers to update component state
    useEffect(() => {
        fetch(
            `${API_GATEWAY}/vaults/${vaultConfig.vault_id}/wallet/${props.walletPublicKey}`
        )
            .then(async (res) => await res.json())
            .then((resp) => {
                setBalance(resp?.balance || 0);
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [vaultConfig.vault_id, lastTransactionTime]);

    // Expose state via props.children
    return (
        <React.Fragment>
            {props.children({ loading, error, balance })}
        </React.Fragment>
    );
}
