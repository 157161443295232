import { Cluster } from "@castlefinance/vault-core";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { Action, Actions } from "../../store/types";
import { getExplorerUrl } from "../../utils/getExplorerUrl";
import { FadeIn } from "../FadeIn";
import { InfoTooltip } from "../Tooltip";
import { TransferCard } from "../TransferCard/component";

// // // //

interface Props {
    actionType: Action;
    cluster: Cluster;
    txSignature: string | null;
    vaultLabel: string;
    resultAmountValue: number;
    resultAmountSymbol: string;
    onDismiss: () => void;
}

const mapActionTypeToLabel = {
    [Actions.deposit]: "Deposit",
    [Actions.withdraw]: "Withdraw",
};

const mapActionTypeToPastTense = {
    [Actions.deposit]: "deposited",
    [Actions.withdraw]: "withdrawn",
};

const mapActionTypeToPreposition = {
    [Actions.deposit]: "into",
    [Actions.withdraw]: "from",
};

export function TransactionSuccessCard(props: Props) {
    const {
        actionType,
        txSignature,
        cluster,
        vaultLabel,
        resultAmountValue,
        resultAmountSymbol,
        onDismiss,
    } = props;

    const actionPastTense = mapActionTypeToPastTense[actionType];
    const actionPreposition = mapActionTypeToPreposition[actionType];
    const actionLabel = mapActionTypeToLabel[actionType];
    return (
        <TransferCard
            label={`${actionLabel} Successful`}
            onDismiss={() => {
                onDismiss();
            }}
        >
            <FadeIn show>
                <div className="p-6 h-64 select-none flex flex-col justify-center">
                    <div className="text-lg text-center">
                        <span className="text-gray-300 text-normal">
                            You have successfully {actionPastTense}{" "}
                        </span>
                        <br />
                        <span className="text-white font-semibold">
                            {resultAmountValue.toFixed(3)} {resultAmountSymbol}
                        </span>{" "}
                        <span className="text-gray-300 text-normal">
                            {actionPreposition}
                        </span>{" "}
                        <span className="text-white font-semibold">
                            {vaultLabel}
                        </span>
                        .
                    </div>

                    {txSignature !== null && (
                        <InfoTooltip message="Open in Solana Explorer">
                            {({ setTriggerRef }) => (
                                <a
                                    ref={setTriggerRef}
                                    href={getExplorerUrl({
                                        txSignature: txSignature,
                                        cluster: cluster,
                                    })}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-indigo-300 hover:text-indigo-400 flex items-center mt-4"
                                >
                                    <div className="truncate w-full font-mono">
                                        {txSignature}
                                    </div>
                                    <ExternalLinkIcon className="h-5 w-5 pb-1 inline-block" />
                                </a>
                            )}
                        </InfoTooltip>
                    )}
                </div>
            </FadeIn>

            <div className="border-t border-light-stroke p-6">
                <button
                    onClick={() => {
                        onDismiss();
                    }}
                    data-testid="back-to-vault"
                    className="bg-transparent select-none hover:bg-light-stroke border border-light-stroke transition-colors text-white flex items-center justify-between py-6 px-8 text-xl font-semibold rounded-xl w-full disabled:opacity-70 disabled:cursor-not-allowed"
                >
                    <span>Back to Vault</span>
                    <CheckIcon className="h-6" />
                </button>
            </div>
        </TransferCard>
    );
}
