import { useEffect, useState } from "react";
import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";
import { notif } from "../components/Notification";
import { usePrevious } from "../hooks/usePrevious";

// // // //

/**
 * useConnectedWallet
 * Implements Solana Wallet Adaptor + return wallet state
 * Handles connected/disconnected notifications
 */
export function useConnectedWallet() {
    const wallet = useWallet();
    const prevWallet = usePrevious<WalletContextState>(wallet);

    const [connectedWallet, setConnectedWallet] =
        useState<WalletContextState | null>();

    useEffect(() => {
        if (wallet?.publicKey && wallet?.connected != prevWallet?.connected) {
            setConnectedWallet(wallet);
            notif({ type: "success", message: "Wallet connected" });
        }
        if (
            !wallet?.publicKey &&
            !wallet?.connected != !prevWallet?.connected
        ) {
            setConnectedWallet(null);
            notif({ type: "info", message: "Wallet disconnected" });
        }
    }, [prevWallet, wallet]);

    return connectedWallet;
}
