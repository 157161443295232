import "react-popper-tooltip/dist/styles.css";
import * as PopperJS from "@popperjs/core";
import { Tooltip } from "./component";
import React from "react";
import classNames from "classnames";

// // // //

interface Props {
    title?: string;
    message: string;
    contentClassName?: string;
    titleClassName?: string;
    messageClassName?: string;
    placement?: PopperJS.Placement;
    children: (childProps: {
        setTriggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    }) => React.ReactNode;
}

/**
 * Informational tooltip component
 */
export function InfoTooltip(props: Props) {
    const {
        contentClassName,
        titleClassName = "text-lg text-white mb-2",
        messageClassName = "text-md text-gray-200",
    } = props;

    const tooltipContent = (
        <div
            className={classNames(
                "max-w-[300px] px-4 pt-3 pb-4",
                contentClassName
            )}
        >
            {props.title && <h4 className={titleClassName}>{props.title}</h4>}
            <p className={messageClassName}>{props.message}</p>
        </div>
    );

    return (
        <Tooltip
            placement={props.placement}
            tooltipWrapperClassName="bg-dark-200 border-light-stroke rounded-xl"
            tooltipContent={tooltipContent}
        >
            {props.children}
        </Tooltip>
    );
}
