import { Icon, IconTypes } from "../../components/Icon";
import { InlineButton } from "../../components/InlineButton";

// // // //

/**
 * VaultListGroupHeader
 * Renders a header for a group of VaultCard components
 */
export function VaultListGroupHeader(props: { label: string; href?: string }) {
    const { label, href } = props;
    return (
        <div className="flex items-center justify-between border-b border-light-stroke py-6 my-10 select-none">
            <div className="flex items-center">
                <Icon type={IconTypes.chamferedBox} className="h-4" />
                <h3 className="text-lg font-bold text-gray-50 ml-3">{label}</h3>
            </div>

            {href && (
                <InlineButton text="Learn More" href={href} target="_blank" />
            )}
        </div>
    );
}
