import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FadeIn } from "../FadeIn";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { DotDotDots } from "../DotDotDots";

// // // //

/**
 * PostTransactionMessage
 * Displays a message after a successful transaction
 */
export function PostTransactionMessage(props: { show: boolean }) {
    return (
        <FadeIn show={props.show}>
            <div className="absolute inset-x-0">
                <div className="p-2 bg-secondary-400 shadow-lg sm:p-3">
                    <div className="flex items-center justify-center flex-wrap max-w-7xl mx-auto">
                        <div className="flex items-center w-full justify-center">
                            <FontAwesomeIcon
                                className="h-4 w-4 text-white"
                                icon={faClock}
                            />
                            <p className="ml-2 font-medium text-white truncate text-sm">
                                <span className="inline mr-1">
                                    It may take up to 60 seconds to see your
                                    updated wallet balance in the app.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </FadeIn>
    );
}
