import React from "react";
import { DotDotDots } from "../DotDotDots";
import { Emdash } from "../Punctuation";

// // // //

/**
 * UserPosition
 * Display the balance of the User's wallet
 */
export function UserPosition(props: { loading: boolean; balance: number }) {
    const { loading, balance } = props;

    // Render position w/ loading state
    return (
        <div className="rounded border border-light-stroke p-4 flex flex-col justify-center w-full">
            <div className="flex items-center justify-between">
                <p className="text-white text-lg font-normal">Your Position</p>
            </div>

            <div className="flex items-center justify-between mt-4">
                <p className="text-gray-100 whitespace-nowrap text-md font-normal">
                    Estimated Value
                </p>
                <p className="text-white text-lg font-medium ml-2">
                    {loading && <DotDotDots renderPlaceholder length={5} />}
                    {!loading && balance === 0 && (
                        <div className="ml-4 mr-2">
                            <Emdash />
                        </div>
                    )}

                    {!loading && balance !== 0 && (
                        <React.Fragment>
                            $
                            {balance.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </React.Fragment>
                    )}
                </p>
            </div>
        </div>
    );
}
