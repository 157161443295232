import styles from "./styles.module.css";
import { Icon, IconType, IconTypes } from "../Icon";
import classnames from "classnames";
import React from "react";
import { DotDotDots } from "../DotDotDots";
import { SOL_ICON, USDC_ICON } from "../../consts";
import { VaultWalletBalanceFetcher } from "../VaultWalletBalanceFetcher";
import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import { Emdash } from "../Punctuation";

// // // //

interface Props {
    loading: boolean;
    active?: boolean;
    className?: string;

    tokenLabel: string;
    tokenIcon: string;

    strategyLabel: string;
    strategyDescription: string;

    currentApy: number;
    currentApyPercentChange: number;

    totalValueUsd: number;
    totalValueToken: number;

    portYieldPercent: number;
    jetYieldPercent: number;
    solendYieldPercent: number;

    children?: React.ReactNode;
}

function YieldRow(props: {
    name: string;
    loading: boolean;
    icon: IconType;
    yieldPercent: number;
}) {
    const { name, loading, icon, yieldPercent } = props;
    return (
        <div
            className={classnames(
                "flex items-center rounded-lg mt-2 py-2 px-3",
                styles.yieldRow
            )}
        >
            <Icon type={icon} className="w-4" />
            {loading && (
                <p className="text-white font-semibold ml-3 text-[16px]">
                    {name} @ <DotDotDots />
                </p>
            )}
            {!loading && (
                <p className="text-white font-semibold ml-3 text-[16px]">
                    {yieldPercent !== 0 &&
                        `${name} @ ${(yieldPercent * 100).toFixed(2)}%`}
                    {yieldPercent === 0 && (
                        <React.Fragment>
                            {name} <Emdash className="ml-2" />
                        </React.Fragment>
                    )}
                </p>
            )}
        </div>
    );
}

export function VaultCardContent(props: Props) {
    const {
        loading,
        tokenLabel,
        tokenIcon,
        strategyLabel,
        strategyDescription,
        currentApy,
        totalValueUsd,
        portYieldPercent,
        jetYieldPercent,
        solendYieldPercent,
        children,
    } = props;

    return (
        <React.Fragment>
            <div
                className={classnames(
                    "p-6 flex items-center justify-between w-full rounded-xl",
                    styles.header
                )}
            >
                <h4 className="text-white text-2xl font-bold">${tokenLabel}</h4>
                <img
                    src={tokenIcon}
                    draggable={false}
                    alt={`${tokenLabel} Icon`}
                    className="h-8"
                />
            </div>

            <div className="px-6 py-6 flex-grow w-full">
                <p className="text-white font-medium text-[18px]">
                    {strategyLabel}
                </p>
                <p className="text-white font-normal text-[14px]">
                    {strategyDescription}
                </p>
            </div>

            <div className="flex justify-between w-full px-6">
                <div className="flex flex-col w-1/2">
                    <p className="text-gray-100 mb-3 text-[14px]">
                        Current APY
                    </p>
                    <p className="text-white font-medium text-[24px]">
                        {loading && <DotDotDots renderPlaceholder />}
                        {!loading && currentApy === 0 && <Emdash />}
                        {!loading &&
                            currentApy !== 0 &&
                            `${(currentApy * 100).toFixed(2)}%`}
                    </p>
                </div>

                <div className="flex flex-col w-1/2">
                    <p className="text-gray-100 mb-3 text-[14px]">
                        Total in Vault
                    </p>
                    <p className="text-white font-medium text-[24px]">
                        {loading && <DotDotDots />}
                        {!loading && totalValueUsd === 0 && <Emdash />}
                        {!loading && totalValueUsd !== 0 && (
                            <React.Fragment>
                                ${Math.floor(totalValueUsd).toLocaleString()}
                            </React.Fragment>
                        )}
                    </p>
                </div>
            </div>

            <div className="px-6 py-6 w-full">
                <p className="text-white font-light text-[14px]">
                    Current Yield Sources
                </p>

                <YieldRow
                    name="Port"
                    loading={loading}
                    icon={IconTypes.port}
                    yieldPercent={portYieldPercent}
                />
                {/* <YieldRow
                    name="Jet"
                    loading={loading}
                    icon={IconTypes.jet}
                    yieldPercent={jetYieldPercent}
                /> */}
                <YieldRow
                    name="Solend"
                    loading={loading}
                    icon={IconTypes.solend}
                    yieldPercent={solendYieldPercent}
                />
            </div>

            {children && <React.Fragment>{children}</React.Fragment>}
        </React.Fragment>
    );
}

interface HrefProps {
    href?: string;
    onClick?: never;
}
interface OnClickProps {
    href?: never;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
type ClickableProps = HrefProps | OnClickProps;

export const MAP_TOKEN_TO_ICON: Record<string, string> = {
    SOL: SOL_ICON,
    USDC: USDC_ICON,
};

export function VaultCard(
    props: Props & ClickableProps & { loading: boolean }
) {
    const { loading, className = "", active = false } = props;
    const baseCss = classnames(
        className,
        "col-span-1 flex flex-col rounded-2xl transition-all bg-dark-100 select-none",
        {
            [styles.active]: active,
        }
    );

    if (loading) {
        <div className={classnames(baseCss, styles.vaultCard)}>
            <VaultCardContent {...props} />
        </div>;
    }

    if (props.onClick) {
        return (
            <button
                onClick={props.onClick}
                className={classnames(
                    "text-left",
                    baseCss,
                    styles.vaultCard,
                    styles.vaultCardHoverable
                )}
            >
                <VaultCardContent {...props} />
            </button>
        );
    }

    if (props.href) {
        return (
            <a
                href={props.href}
                onClick={props.onClick}
                className={classnames(
                    baseCss,
                    styles.vaultCard,
                    styles.vaultCardHoverable
                )}
            >
                <VaultCardContent {...props} />
            </a>
        );
    }

    return (
        <div className={classnames(baseCss, styles.vaultCard)}>
            <VaultCardContent {...props} />
        </div>
    );
}

export function VaultCardList(props: { children: React.ReactNode }) {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
            {props.children}
        </div>
    );
}

// // // //

/**
 * VaultPosition
 * Fetches VaultPosition for props.publicKey
 */
export function VaultPosition(props: {
    vaultConfig: VaultConfig<DeploymentEnvs>;
    publicKey: string;
}) {
    return (
        <VaultWalletBalanceFetcher
            vaultConfig={props.vaultConfig}
            walletPublicKey={props.publicKey}
        >
            {({ loading, balance }) => (
                <div className="flex items-center justify-between w-full border-t border-light-stroke py-6 px-6">
                    <p className="text-gray-100 font-light text-[16px]">
                        Your Position
                    </p>
                    {loading && (
                        <p className="text-white font-semibold text-[24px]">
                            <DotDotDots />
                        </p>
                    )}
                    {!loading && (
                        <p className="text-white font-semibold text-[24px]">
                            $
                            {balance?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }) || "0.00"}
                        </p>
                    )}
                </div>
            )}
        </VaultWalletBalanceFetcher>
    );
}
