import { State } from "zustand";
import * as anchor from "@project-serum/anchor";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { VaultClient } from "@castlefinance/vault-sdk";
import { PublicKey } from "@solana/web3.js";
import { TokenRegistryData } from "../consts";

// // // //

export enum Actions {
    deposit = "Deposit",
    withdraw = "Withdraw",
}
export type Action = "Deposit" | "Withdraw";

/**
 * Type for a maps of string -> TokenAccountData
 * Key is stringified Token public key
 */
export type TokenMap = Map<string, TokenAccountData | null>;

/**
 * Only SPL data that can be retrieved from the blockchain
 * Example: https://solscan.io/token/EbTMdf2EZz9a9Mj9bmPPzzGpp9bgUZd2ZBLAXT6set1C?cluster=devnet
 * Example: https://solscan.io/token/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R
 */
export interface TokenAccountData {
    account: PublicKey; // TODO - annotate
    mint: PublicKey; // TODO - annotate
    owner: PublicKey; // TODO - annotate
    decimals: number; // Decimals from SPL
    uiAmount: number; // Returned from Solana SDK
    uiAmountString: string; // Returned from Solana SDK
}

/**
 * TokenRegistryMap
 * Maps PublicKey string to TokenRegistryData
 */
export type TokenRegistryMap = {
    [key: string]: TokenRegistryData | null | undefined;
};

export interface CastleStore extends State {
    provider: anchor.Provider | null;
    vaultClient: VaultClient | null;
    vaultLoadError: boolean;
    loadingWalletBalances: boolean;
    tokenMap: TokenMap;
    tokenRegistryMap: TokenRegistryMap;
    resetTokens: () => void;
    refresh: (
        connection: anchor.web3.Connection,
        wallet?: WalletContextState
    ) => void;
}
