import { PublicKey } from "@solana/web3.js";
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    Token,
} from "@solana/spl-token";

// // // //

/**
 * Calculates the associated token account for a mint and public key
 * @param mint mint of the token
 * @param account account that will own the ATA
 * @returns the token ATA
 */
export async function calculateATA(
    mint: PublicKey,
    account: PublicKey
): Promise<PublicKey> {
    const generatedUserLpATA = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        account
    );
    return generatedUserLpATA;
}
