import { ArrowRightIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React from "react";
import { Action, Actions } from "../../store/types";
import { FadeIn } from "../FadeIn";
import { LoadingSpinner } from "../LoadingSpinner";
import { TransferCard } from "../TransferCard/component";

// // // //

interface Props {
    loading: boolean;
    confirming: boolean;
    actionType: Action;
    vaultLabel: string;
    actionAmountValue: number;
    actionAmountSymbol: string;
    receiveAmountValue: number;
    receiveAmountSymbol: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const mapActionTypeToLabel = {
    [Actions.deposit]: "Deposit",
    [Actions.withdraw]: "Withdraw",
};

function CardLabel({ children }: { children: React.ReactNode }) {
    return (
        <p className="text-gray-200 font-normal text-lg leading-10">
            {children}
        </p>
    );
}

function CardValue({
    children,
    loading,
}: {
    children: React.ReactNode;
    loading: boolean;
}) {
    if (loading) {
        return (
            <div className="animate-pulse bg-light-stroke rounded">
                <div className="invisible">{children}</div>
            </div>
        );
    }
    return (
        <FadeIn show duration={700}>
            <p className="text-white text-xl leading-10">{children}</p>
        </FadeIn>
    );
}

function Group({
    children,
}: {
    // children: React.ReactNode | React.ReactNode[];
    children: React.ReactNode;
}) {
    return (
        <div className="flex items-center justify-between select-none">
            {children}
        </div>
    );
}

export function ReviewTransactionCard(props: Props) {
    const {
        loading,
        confirming,
        actionType,
        vaultLabel,
        actionAmountValue,
        actionAmountSymbol,
        receiveAmountValue,
        receiveAmountSymbol,
        onCancel,
        onConfirm,
    } = props;

    const actionLabel = mapActionTypeToLabel[actionType];

    return (
        <TransferCard
            label={`Review ${actionLabel}`}
            onDismiss={() => onCancel()}
        >
            <div className="p-6 h-64 flex flex-col justify-center">
                <FadeIn show duration={700}>
                    <Group>
                        <CardLabel>Vault</CardLabel>
                        <CardValue loading={loading}>{vaultLabel}</CardValue>
                    </Group>
                    <Group>
                        <CardLabel>{actionLabel} Amount</CardLabel>
                        <CardValue loading={loading}>
                            {actionAmountValue} {actionAmountSymbol}
                        </CardValue>
                    </Group>
                    <Group>
                        <CardLabel>Receive Amount</CardLabel>
                        <CardValue loading={loading}>
                            {receiveAmountValue} {receiveAmountSymbol}
                        </CardValue>
                    </Group>
                </FadeIn>
            </div>

            <div className="border-t border-light-stroke p-6">
                <button
                    disabled={loading || confirming}
                    onClick={() => {
                        onConfirm();
                    }}
                    data-testid="complete-transaction"
                    className={classNames(
                        "bg-secondary-400 select-none disabled:hover:bg-secondary-400 hover:bg-[#3535c4] transition-colors text-white flex items-center justify-between py-6 px-8 text-xl font-semibold rounded-xl w-full disabled:opacity-70 disabled:cursor-not-allowed",
                        {
                            "opacity-70 cursor-not-allowed":
                                loading || confirming,
                        }
                    )}
                >
                    {confirming && (
                        <>
                            <span>Finalizing {actionLabel}...</span>
                            <LoadingSpinner size={6} show={true} />
                        </>
                    )}
                    {!confirming && (
                        <>
                            <span>Complete {actionLabel}</span>
                            <ArrowRightIcon className="h-6" />
                        </>
                    )}
                </button>
            </div>
        </TransferCard>
    );
}
