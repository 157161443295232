import React from "react";

// // // //

interface Props {
    interval?: number;
    children: (childProps: { lastIntervalAt: number }) => React.ReactNode;
}

interface State {
    lastIntervalAt: number;
}

/**
 * RefreshInterval
 * Implements a React-safe component that passes down the latest Unix time on a per-determined interval
 */
export class RefreshInterval extends React.Component<Props, State> {
    intervalID: undefined | ReturnType<typeof setTimeout> = undefined;

    constructor(props: Props) {
        super(props);
        // Set initial state.lastIntervalAt value to 0
        this.state = {
            lastIntervalAt: Date.now(),
        };
    }

    componentWillUnmount() {
        // Clear this.intervalID if component unmounts
        if (this.intervalID === undefined) {
            return;
        }
        clearInterval(this.intervalID);
    }

    componentDidMount() {
        const { interval = 30000 } = this.props;

        this.intervalID = setInterval(() => {
            // Increment count
            this.setState({
                lastIntervalAt: Date.now(),
            });
        }, interval);
    }

    render() {
        // Pass lastIntervalAt down via props.children
        return (
            <React.Fragment>
                {this.props.children({
                    lastIntervalAt: this.state.lastIntervalAt,
                })}
            </React.Fragment>
        );
    }
}
