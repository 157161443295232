import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import React from "react";
import { VaultListFetcher } from "../VaultListFetcher";

// // // //

/**
 * VaultIndividualFetcher
 * Fetches a single VaultConfig via api.castle.finance
 */
export function VaultIndividualFetcher(props: {
    vaultID: string;
    children: (childProps: {
        loading: boolean;
        error: boolean;
        vaultConfig: VaultConfig<DeploymentEnvs> | null;
    }) => React.ReactNode;
}) {
    const { vaultID } = props;

    // Implement VaultListFetcher and pass results to props.children
    return (
        <VaultListFetcher>
            {({ loading, error, filteredVaults }) => {
                // Lookup VaultConfig
                const vault: VaultConfig<DeploymentEnvs> | undefined =
                    filteredVaults.find((v) => v.vault_id === vaultID);

                // Return VaultListFetcher state + VaultConfig
                return (
                    <React.Fragment>
                        {props.children({
                            loading,
                            error,
                            vaultConfig: vault || null,
                        })}
                    </React.Fragment>
                );
            }}
        </VaultListFetcher>
    );
}
