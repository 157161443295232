import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React from "react";

// // // //

interface Props {
    text: React.ReactNode;
    className?: string;
}

interface HrefProps {
    href?: string;
    target?: "_blank";
    onClick?: never;
    disabled?: never;
}
interface OnClickProps {
    href?: never;
    target?: never;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
}
type ClickableProps = HrefProps | OnClickProps;

export function InlineButton(props: Props & ClickableProps) {
    const {
        text,
        className = "",
        disabled = false,
        onClick,
        href,
        target,
    } = props;
    const anchorRel = target ? "noreferrer" : undefined;

    const content = (
        <React.Fragment>
            {text}
            <FontAwesomeIcon
                icon={faArrowRight}
                className={classNames(
                    "text-secondary-400 transition-colors h-4 ml-1.5",
                    {
                        "group-hover:text-secondary-450": !disabled,
                        "cursor-not-allowed opacity-40": disabled,
                    }
                )}
            />
        </React.Fragment>
    );

    const css = classNames(
        className,
        "group bg-transparent text-secondary-400 font-normal transition-colors flex items-center whitespace-nowrap",
        {
            "hover:text-secondary-450": !disabled,
            "cursor-not-allowed opacity-40": disabled,
        }
    );

    // Render as a button
    if (onClick) {
        return (
            <button onClick={onClick} disabled={disabled} className={css}>
                {content}
            </button>
        );
    }

    // Render as a link
    return (
        <a href={href} target={target} rel={anchorRel} className={css}>
            {content}
        </a>
    );
}
