import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { datadogRum } from "@datadog/browser-rum";
import { getEnvironmentVars } from "./config";
require("@solana/wallet-adapter-react-ui/styles.css");

// // // //

// Init Datadog RUM session
const env = getEnvironmentVars();
if (env.datadogApplicationID && env.datadogClientToken) {
    datadogRum.init({
        applicationId: env.datadogApplicationID,
        clientToken: env.datadogClientToken,
        site: "datadoghq.com",
        service: "app",
        env: "prod",
        version: "0.1.0",
        sampleRate: 100,
        premiumSampleRate: 0,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
    });

    // Start RUM session
    datadogRum.startSessionReplayRecording();
}

// // // //

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
