import classnames from "classnames";
import { useState } from "react";
import { Action, Actions } from "../../store/types";
import { InfoTooltip } from "../Tooltip";

// // // //

function SwapButton(props: {
    actionType: string;
    action: Action;
    onClick: () => void;
    disabled?: boolean;
}) {
    const { actionType, action, onClick, disabled } = props;

    if (disabled) {
        return (
            <InfoTooltip message="Deposits are not supported" placement="top">
                {({ setTriggerRef }) => (
                    <button
                        ref={setTriggerRef}
                        disabled={disabled}
                        className={classnames(
                            "w-1/2 relative text-lg border-transparent rounded-xl font-semibold bg-transparent inline-flex justify-center items-center px-6 py-4 border focus:z-10 transition-colors duration-200 focus:outline-none focus:ring-none",
                            {
                                "text-cf-green-100": action === actionType,
                                "text-gray-400": action !== actionType,
                                "cursor-not-allowed": disabled,
                            }
                        )}
                        onClick={() => onClick()}
                    >
                        {actionType}
                    </button>
                )}
            </InfoTooltip>
        );
    }
    return (
        <button
            disabled={disabled}
            className={classnames(
                "w-1/2 relative text-lg border-transparent rounded-xl font-semibold bg-transparent inline-flex justify-center items-center px-6 py-4 border focus:z-10 transition-colors duration-200 focus:outline-none focus:ring-none",
                {
                    "text-cf-green-100": action === actionType,
                    "text-gray-400": action !== actionType,
                    "cursor-not-allowed": disabled,
                }
            )}
            onClick={() => onClick()}
        >
            {actionType}
        </button>
    );
}

/**
 * Render Deposit + Withdraw Buttons
 */
export function SwapControls(props: {
    action: Action;
    onClickDeposit: () => void;
    onClickWithdraw: () => void;
}) {
    const [lastClick, setLastClick] = useState(0);
    const { action, onClickDeposit, onClickWithdraw } = props;
    return (
        <span className="relative z-0 inline-flex items-center shadow-sm rounded-md w-full bg-muted">
            <SwapButton
                action={action}
                actionType={Actions.deposit}
                disabled // NOTE - this is disabled
                onClick={() => {
                    if (action !== Actions.deposit) {
                        const now = Date.now();
                        if (lastClick < Date.now() - 500) {
                            onClickDeposit();
                            setLastClick(now);
                        }
                    }
                }}
            />
            {/* Render the slider between deposit + withdraw buttons */}
            <button
                className="w-1/2 absolute transition-all border-2 border-cf-green-100 rounded-xl text-lg font-semibold bg-transparent inline-flex justify-center items-center px-6 py-4 focus:z-10 duration-200"
                style={{
                    transform:
                        action === Actions.deposit
                            ? "translateX(0)"
                            : "translateX(100%)",
                }}
                onClick={() => {
                    if (action !== Actions.deposit) {
                        const now = Date.now();
                        if (lastClick < Date.now() - 500) {
                            onClickWithdraw();
                            setLastClick(now);
                        }
                    }
                    onClickWithdraw();
                }}
            >
                <span className="invisible">.</span>
            </button>
            <SwapButton
                action={action}
                actionType={Actions.withdraw}
                onClick={() => {
                    if (action !== Actions.withdraw) {
                        onClickWithdraw();
                    }
                }}
            />
        </span>
    );
}
