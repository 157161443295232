import { ChartTooltip, PieArc, PieArcSeries, PieChart } from "reaviz";
import { Icon, IconTypes } from "../../components/Icon";
import { VaultData } from "../../components/VaultDataFetcher/types";
import { ApyLabels } from "./ApyChart";

// // // //

const mapApyToColor: { [key: string]: string } = {
    ["Reserve"]: "#4fff95",
    [ApyLabels.castle]: "#4fff95",
    [ApyLabels.solend]: "#ff5c28",
    [ApyLabels.jet]: "#7BCBCD",
    [ApyLabels.port]: "#826DF9",
};

/**
 * TooltipContent
 * Render content for donut chart tooltip
 */
function TooltipContent(props: { label: string; value: number }) {
    const { label, value } = props;

    // Return null if either prop is undefined
    if (label === undefined || value === undefined) {
        return null;
    }

    // Define iconType to render alongside label
    let iconType = undefined;
    if (label === "Jet") {
        iconType = IconTypes.jet;
    } else if (label === "Solend") {
        iconType = IconTypes.solend;
    } else if (label === "Port") {
        iconType = IconTypes.port;
    } else if (label === "Reserve") {
        iconType = IconTypes.castleLogo;
    }

    return (
        <div className="flex flex-col">
            <div className="flex items-center justify-center mb-2">
                {iconType !== undefined && (
                    <Icon type={iconType} className="h-4 mr-2" />
                )}
                <p className="text-lg">{label}</p>
            </div>
            <p
                className="flex items-center justify-center font-mono"
                style={{ color: mapApyToColor[label as ApyLabels] }}
            >
                ${Math.floor(value).toLocaleString()}
            </p>
        </div>
    );
}

/**
 * AllocationDonutChart
 * Renders donut chart for Vault allocations
 */
export function AllocationDonutChart(props: { vaultData: VaultData }) {
    const { vaultData } = props;

    const height = 350;
    const width = 350;
    const padAngle = 0.02;
    const padRadius = 200;
    const cornerRadius = 4;

    return (
        <PieChart
            width={width}
            height={height}
            data={[
                {
                    key: "Port",
                    data: vaultData.allocations.port,
                },
                {
                    key: "Jet",
                    data: vaultData.allocations.jet,
                },
                {
                    key: "Solend",
                    data: vaultData.allocations.solend,
                },
                {
                    key: "Reserve",
                    data: vaultData.allocations.reserve,
                },
            ]}
            series={
                <PieArcSeries
                    cornerRadius={cornerRadius}
                    padAngle={padAngle}
                    padRadius={padRadius}
                    doughnut={true}
                    animated
                    arc={
                        <PieArc
                            tooltip={
                                <ChartTooltip
                                    className="rounded-xl border-4 border-dark-100 p-4"
                                    content={(value: any) => {
                                        return (
                                            <TooltipContent
                                                label={value.x}
                                                value={value.y}
                                            />
                                        );
                                    }}
                                />
                            }
                        />
                    }
                    colorScheme={(set) => {
                        if (set.key === "Jet") {
                            return mapApyToColor["Jet"];
                        }
                        if (set.key === "Solend") {
                            return mapApyToColor["Solend"];
                        }
                        if (set.key === "Reserve") {
                            return mapApyToColor["Castle"];
                        }
                        return mapApyToColor["Port"];
                    }}
                />
            }
        />
    );
}
