import toast from "react-hot-toast";
import { XIcon } from "@heroicons/react/solid";
import { getWalletAdapterNetwork } from "../../utils/getWalletAdapterNetwork";
import { getEnvironmentVars } from "../../config";
import classNames from "classnames";

// // // //

export enum NotificationTypes {
    success = "success",
    error = "error",
    info = "info",
}
export type NotificationType = `${NotificationTypes}`;

interface NotificationProps {
    type: NotificationType;
    message: string;
    description?: string;
    txId?: string;
}

export const notif = ({
    type,
    message,
    description,
    txId,
}: NotificationProps): string => {
    // Gets devnet / mainnet network adapter
    // TODO - pull this out of the notification component!
    // TODO - pull this out of the notification component!
    // TODO - pull this out of the notification component!
    const env = getEnvironmentVars();
    const network = getWalletAdapterNetwork({ env });

    return toast.custom((t) => (
        <div
            key={t.id}
            className={classNames(
                "max-w-sm w-full bg-gray-900 border-2 text-gray-100 select-none",
                "shadow-lg rounded-md mt-2 pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden",
                {
                    "animate-enter": t.visible,
                    "animate-leave": !t.visible,
                    "border-status-green-100":
                        type === NotificationTypes.success,
                    "border-status-red-100": type === NotificationTypes.error,
                    "border-light-stroke": type === NotificationTypes.info,
                }
            )}
        >
            <div className="p-4">
                <div className="flex items-center">
                    <div className="ml-2 w-0 flex-1">
                        <div className="font-bold text-fgd-1">{message}</div>
                        {description ? (
                            <p className="mt-0.5 text-sm text-fgd-2">
                                {description}
                            </p>
                        ) : null}
                        {txId ? (
                            <a
                                href={`https://solscan.io/tx/${txId}?cluster=${network}`}
                                className="underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                View transaction {txId.slice(0, 8)}...
                                {txId.slice(txId.length - 8)}
                            </a>
                        ) : null}
                    </div>
                    <div className="ml-4 flex-shrink-0 self-start flex">
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className="bg-bkg-2 default-transition rounded-md inline-flex text-fgd-3 hover:text-fgd-4 focus:outline-none"
                        >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ));
};
