import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import React, { useEffect, useState } from "react";
import { getEnvironmentVars } from "../../config";
import { VaultHistory } from "./types";

// // // //

// Pull API_GATEWAY from EnvironmentVars
const env = getEnvironmentVars();
const API_GATEWAY = env.apiGateway;

interface State {
    loading: boolean;
    error: boolean;
    history: VaultHistory;
}

const EMPTY_HISTORY: VaultHistory = {
    apy: {},
    value: {},
    lpTokenSupply: {},
    solendApy: {},
    portApy: {},
    jetApy: {},
    solendAllocation: {},
    portAllocation: {},
    jetAllocation: {},
    reserveAllocation: {},
};

/**
 * VaultHistoryFetcher
 * Fetches stats via api.castle.finance
 */
export function VaultHistoryFetcher(props: {
    vaultConfig: VaultConfig<DeploymentEnvs>;
    children: (state: State) => React.ReactNode;
}) {
    const { vaultConfig } = props;
    const { token_decimals } = vaultConfig;

    // Hook for loading + error + vaults state
    const [state, setState] = useState<State>({
        loading: true,
        error: false,
        history: EMPTY_HISTORY,
    });

    // Setup useEffect hook to request data on-mount
    // Implements fetch API invocation to fetch stats
    // Setup .then() + .catch() handlers to update component state
    useEffect(() => {
        fetch(
            `${API_GATEWAY}/vaults/${vaultConfig.vault_id}/historical?hours_per_time_bucket=24`
        )
            .then(async (res) => await res.json())
            .then((history: VaultHistory) => {
                // Normalize history.value data to using VaultConfig.token_decimals
                const normalizedHistory: VaultHistory = {
                    ...history,
                };
                Object.keys(history.value).forEach((k) => {
                    normalizedHistory.value[k] = String(
                        parseFloat(history.value[k]) / 10 ** token_decimals
                    );
                });

                setState({
                    loading: false,
                    error: false,
                    history: normalizedHistory,
                });
            })
            .catch(() => {
                setState({
                    loading: false,
                    error: true,
                    history: EMPTY_HISTORY,
                });
            });
    }, []);

    // Expose state via props.children
    return <React.Fragment>{props.children(state)}</React.Fragment>;
}
