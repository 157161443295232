import { useEffect, useState } from "react";
import { useCopyToClipboard } from "../CopyToClipboard/component";
import { Icon, IconTypes } from "../Icon";
import { InfoTooltip } from "../Tooltip";

// // // //

/**
 * VaultPublicKey
 * Displays tooltip to display full public key + link to Solana Explorer
 */
export function VaultPublicKey(props: { publicKey: string }) {
    const { publicKey } = props;

    // Setup CopyToClipboard hook w/ useState to dipslay "Copied!" message
    const [copied, setCopied] = useState<boolean>(false);
    const [copyToClipboard] = useCopyToClipboard(() => {
        setCopied(true);
    });

    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }, [copied]);

    const message: string = copied ? "Copied!" : publicKey;
    const messageClassName: string | undefined = copied
        ? "text-cf-green-100"
        : "font-mono text-white text-base";

    return (
        <div className="flex items-center justify-between bg-gray-800 rounded-full px-4 py-2 flex-shrink w-72 h-[32px]">
            <InfoTooltip
                message={message}
                contentClassName="text-center"
                messageClassName={messageClassName}
                placement="top"
            >
                {({ setTriggerRef }) => (
                    <button
                        ref={setTriggerRef}
                        onClick={() => {
                            copyToClipboard(publicKey);
                        }}
                        className="text-[14px] font-normal tw-truncate flex-grow text-gray-300 truncate select-none pr-3 c border-r border-gray-700 font-mono"
                    >
                        {publicKey}
                    </button>
                )}
            </InfoTooltip>

            <a
                href={`https://explorer.solana.com/address/${publicKey}`}
                target="_blank"
                rel="noreferrer"
                className="flex pl-3 text-gray-400 h-5 items-center opacity-60 hover:opacity-100 transition-opacity"
            >
                <Icon type={IconTypes.linkTo} className="h-4 md:h-6" />
            </a>
        </div>
    );
}
