import classNames from "classnames";
import { Icon, IconTypes } from "../Icon/component";
import { OverviewValue } from "../OverviewValue";
import styles from "./styles.module.css";

// // // //

interface Props {
    loading: boolean;
    totalValue: number;
    totalYield: number;
}

export function OverviewHeader(props: Props) {
    const { loading, totalValue, totalYield } = props;

    return (
        <div
            className={classNames(
                styles.overviewBackground,
                "w-full bg-mid-grey select-none"
            )}
        >
            <div className="max-w-4xl lg:max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:pt-24 lg:px-8">
                <div className="flex items-center justify-between flex-wrap lg:flex-nowrap py-16">
                    <div className="flex flex-col items-start">
                        <Icon type={IconTypes.chamferedBox} className="h-6" />
                        <h2 className="text-4xl font-bold text-white mt-8">
                            Welcome to Vault
                        </h2>
                        <p className="text-lg text-gray-100 mt-4">
                            Vault is a risk-managed, low-maintenance savings
                            account that safely earns yield across a diversified
                            set of DeFi protocols.
                        </p>
                    </div>

                    <div className="py-8 bg-mid-grey border border-light-stroke rounded-lg flex flex-col lg:flex-row lg:items-center justify-center lg:divide-x divide-light-stroke flex-grow lg:flex-grow-0 mt-6 lg:mt-0 space-y-6 lg:space-y-0 lg:ml-6">
                        <OverviewValue
                            loading={loading}
                            value={`$${Math.floor(
                                totalValue
                            ).toLocaleString()}`}
                            label="Total Value Locked"
                        />

                        <OverviewValue
                            loading={loading}
                            value={`$${Math.floor(
                                totalYield
                            ).toLocaleString()}`}
                            label="Total Yield Earned"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
