import classnames from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";
import { Emdash } from "../Punctuation";

export function OverviewValue(props: {
    value: string;
    label: string;
    loading: boolean;
    className?: string;
}) {
    const { label, value, loading, className = "pl-8" } = props;
    const isEmpty: boolean = value === "$0" || value === "0.00%";
    const isPercent: boolean = value.includes("%");
    const isDollar: boolean = value.includes("$");

    return (
        <div className={classnames("pr-16 font-regular", className)}>
            <div className="flex items-center">
                <h4 className="text-lg text-white whitespace-nowrap">
                    {label}
                </h4>
            </div>
            {loading && (
                <div className="animate-pulse h-8 w-full mt-6 bg-gray-700 rounded-lg" />
            )}
            {!loading && (
                <p className="text-3xl text-gray-50 mt-6 flex tracking-wide">
                    {isEmpty && <Emdash />}
                    {!isEmpty && isDollar && (
                        <span className="mr-1 text-2xl relative bottom-0.5">
                            $
                        </span>
                    )}
                    {!isEmpty && isDollar && value.replace("$", "")}
                    {!isEmpty && isPercent && value.replace("%", "")}
                    {!isEmpty && isPercent && (
                        <span className="ml-1 text-2xl relative bottom-0.5">
                            %
                        </span>
                    )}
                </p>
            )}
            {/* <p className="text-lg text-gray-500 mt-2">in the last 24h</p> */}
        </div>
    );
}
