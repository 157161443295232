import { DeploymentEnvs, VaultConfig } from "@castlefinance/vault-core";
import React, { useEffect, useState } from "react";
import { getEnvironmentVars } from "../../config";
import { EMPTY_VAULT_DATA } from "./consts";
import { VaultData } from "./types";

// // // //

// Pull API_GATEWAY from EnvironmentVars
const API_GATEWAY = getEnvironmentVars().apiGateway;

/**
 * VaultDataFetcher
 * Fetches VaultData via api.castle.finance
 */
export function VaultDataFetcher(props: {
    vaultConfig: VaultConfig<DeploymentEnvs>;
    lastTransactionTime?: number;
    children: (childProps: {
        loading: boolean;
        reloading: boolean;
        error: boolean;
        data: VaultData;
    }) => React.ReactNode;
}) {
    const { vaultConfig, lastTransactionTime } = props;

    // Hook for loading state + resulting VaultData
    // Initial state uses emtpy VaultData object for type-safety
    const [loading, setLoading] = useState<boolean>(true);
    const [reloading, setReloading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<VaultData>(EMPTY_VAULT_DATA);

    // Setup useEffect hook to request data on-mount
    // Implements fetch API invocation to fetch VaultData
    // Setup .then() + .catch() handlers to update component state
    useEffect(() => {
        // Set reloading state **after** first fetch (determined by `loading` state)
        if (loading !== true) {
            setReloading(true);
        }
        fetch(`${API_GATEWAY}/vaults/${vaultConfig.vault_id}`)
            .then(async (res) => await res.json())
            .then((vaultData) => {
                setData(vaultData);
                setLoading(false);
                setReloading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
                setReloading(false);
            });
    }, [vaultConfig.vault_id, lastTransactionTime]);

    // Expose state via props.children
    return (
        <React.Fragment>
            {props.children({ loading, reloading, error, data })}
        </React.Fragment>
    );
}
