import { ExternalLinkIcon } from "@heroicons/react/solid";
import React from "react";
import { CASTLE_URLS } from "../../consts";

// // // //

export function AllocationsSection(props: { children: React.ReactNode }) {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 pl-4">
            <div className="flex items-center col-span-1 md:col-span-1">
                <p className="text-lg ">
                    The Castle Vault optimizes allocations to acheive the
                    highest risk-adjusted return. <br />
                    <br />
                    It employs various risk-management measures, including
                    over-exposure controls and liquidity monitoring. Read our{" "}
                    <a
                        href={CASTLE_URLS.docs}
                        target="_blank"
                        rel="noreferrer"
                        className="text-indigo-300 hover:text-indigo-400"
                    >
                        docs{" "}
                        <ExternalLinkIcon className="h-5 w-5 pb-1 -ml-1 inline-block" />
                    </a>
                    for more information.
                </p>
            </div>
            <div className="col-span-1 flex justify-center">
                {props.children}
            </div>
        </div>
    );
}
